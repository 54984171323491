import {Component, OnInit} from '@angular/core';
import {Subscription} from "rxjs/Rx";
import {Inscription_Formation} from "../../shared/models/inscription_formation";
import {PostGraduateTrainingService} from "../../shared/services/post-graduate-training.service";
import {ActivatedRoute} from "@angular/router";
import {Formation} from "../../shared/models/formation";
import {Utils} from "../../shared/utils";
import {environment} from "../../../environments/environment";
import {UserService} from "../../shared/services/user.service";

declare var swal: any;
declare var jQuery: any;

@Component({
  selector: 'app-list-validated-inscriptions',
  templateUrl: './list-validated-inscriptions.component.html',
  styleUrls: ['./list-validated-inscriptions.component.css']
})
export class ListValidatedInscriptionsComponent implements OnInit {

  busy: Subscription;
  inscriptionsFormation: Inscription_Formation[] = [];
  baseUrl: string;


  isAdmin: boolean;
  selectedPaiementPath: string;

  formationId: number;
  sessionId: number;

  formation: Formation = new Formation();

  identifiant: string;

  constructor(private route: ActivatedRoute,
              private postGraduateTrainingService: PostGraduateTrainingService,
              private userService: UserService) {
    this.formationId = parseInt(this.route.snapshot.paramMap.get('formationId'), 0);
    this.sessionId = parseInt(this.route.snapshot.paramMap.get('sessionId'), 0);

    this.baseUrl = environment.baseUrl + "/";
    this.isAdmin = this.userService.checkIfAdminHasRole(1);
  }

  ngOnInit() {
    this.loadFormation();
    this.loadCandidatsByFormation();

  }

  loadCandidatsByFormation() {
    this.busy = this.postGraduateTrainingService.getValidatedInscriptionsByFormation(this.sessionId, this.formationId)
      .subscribe(
        (data: Inscription_Formation[]) => {
          console.log(data);
          this.inscriptionsFormation = data;
          Utils.initializeDataTables('datatable-basic', 20, 5);

        },
        (error) => {
          if (error.status === 401) {
            swal("Erreur", "Vous n'avez pas droit d'accéeder à cette formation", "error");
          }
        }
      )
  }

  showRecu(paiementPath) {
    this.selectedPaiementPath = paiementPath;
    jQuery('#modal_recu_paiement').modal();
  }

  changeStatusInscription(inscriptionId, index, status) {
    this.busy = this.postGraduateTrainingService
      .changeStautsInscription(inscriptionId, status)
      .subscribe(
        (data) => {
          this.inscriptionsFormation[index].status = status;
          if (status === 3)
            this.inscriptionsFormation[index].paiement = null;
          swal("Succées", "Changement de status effectué avec succées", "success");
        },
        (error) => {

        }
      )
  }

  loadFormation() {
    this.busy = this.postGraduateTrainingService.getFormationById(this.formationId)
      .subscribe(
        (data: Formation) => {
          this.formation = data;
        },
        (error) => {
          if (error.status === 401) {
            swal("Erreur", "Vous n'avez pas droit d'accéeder à cette formation", "error");
          }

        }
      )
  }

  openModalCIN() {
    jQuery('#modal_candidat_cin').modal();
  }

  addCandidat() {


    this.busy = this.postGraduateTrainingService
      .addCandidateByIdent(this.sessionId, this.formationId, this.identifiant)
      .subscribe(
        (data: any) => {
          swal('Succées', 'Le candidat ' + data.first_name + " " + data.last_name + ' a été ajoutée avec succées', 'success');
        },
        (error) => {
          console.log(error);
          if (error.status === 404) {
            swal('Erreur', "Le candidat n'existe pas dans la plateforme", 'error');
          } else {
            swal('Erreur', "Une erreur est survenu", 'error');
          }
        }
      );

    this.identifiant = "";
    jQuery('#modal_candidat_cin').modal('hide');

  }
}
