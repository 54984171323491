import {Injectable} from '@angular/core';
import {GenericService} from './generic.service';
import {HttpClient} from "@angular/common/http";
import {catchError} from 'rxjs/operators';
import {RegistrationYearUniversity} from "../models/RegistrationYearUniversity";
import {StorageService} from "./storage.service";
import {environment} from "../../../environments/environment";

@Injectable()
export class InscriptionService extends GenericService {

  constructor(private http: HttpClient, private stoarageService: StorageService) {
    super();
  }


  openInscription(registrationYear: RegistrationYearUniversity) {
    const headers = this.headers.set("Authorization", "Bearer " + this.stoarageService.read("admin-token"));
    const url = environment.baseUrl + "/admin/registration/university-year/open";

    return this.http.post<any>(url, registrationYear,
      {
        headers: headers
      }
    ).pipe(catchError(this.handleErrors));
  }

  getCurrentInscription(year_university: string, cycle: number) {
    const headers = this.headers.set("Authorization", "Bearer " + this.stoarageService.read("admin-token"));
    const url = environment.baseUrl + "/admin/registration/university-year/get";

    return this.http.post<any>(url, {
        year_university: year_university,
        cycle: cycle
      },
      {
        headers: headers
      })
      .pipe(catchError(this.handleErrors));
    ;
  }
}
