import {NgModule} from "@angular/core";
import {CommonModule} from "@angular/common";
import {SharedModule} from "../shared/shared.module";
import {ListTrainingComponent} from "./list-training/list-training.component";
import {ManageTrainingComponent} from "./manage-training/manage-training.component";
import {PostGraduateTrainingRouting} from "./post-graduate-training.routing";
import {ListDemandsTrainingComponent} from './list-demands-training/list-demands-training.component';
import {ListDemandsPerTrainingComponent} from "./list-demands-per-training/list-demands-per-training.component";
import {ListValidatedInscriptionsComponent} from "./list-validated-inscriptions/list-validated-inscriptions.component";
import {ManageSessionPostComponent} from './manage-session-post/manage-session-post.component';
import {SessionPostFormComponent} from './session-post-form/session-post-form.component';

@NgModule({
  imports: [
    PostGraduateTrainingRouting,
    CommonModule,
    SharedModule
  ],
  declarations: [
    ListValidatedInscriptionsComponent,
    ListDemandsPerTrainingComponent,
    ListTrainingComponent,
    ManageTrainingComponent,
    ListDemandsTrainingComponent,
    ManageSessionPostComponent,
    SessionPostFormComponent
  ]
})
export class PostGraduateTrainingModule {
}
