import {Level} from "../level";
import {SessionOU} from "./session-ou";
import {GroupOU} from "./GroupOU";
import {OptionalUnit} from "./optional-unit";

export class SessionChoixOU {
  id_session_choix_ou: number;

  title: string;
  start_date: string;
  end_date: string;
  year_university: string;

  affectation: number;

  level: Level = new Level();

  id_level: number;

  groupes_ou: GroupOU[] = [];

  sessions_ou: SessionOU[] = [];
  optional_units: OptionalUnit[] = [];
}
