import {Teacher} from "../Teacher";
import {Level} from "../level";
import {Domaine} from "./domaine";

export class OptionalUnit {
  id_optional_unit: number;

  title: string;
  description: string;
  objectif: string;
  coordinateur: Teacher;
  id_coordinateur: number;
  code: string;
  levelIds: number[] = [];

  id_domaine: number;

  domaine: Domaine = new Domaine();

  levels: Level[] = [];

  updated_at: string;
  created_at: string;
}
