import {Seminar} from "./Seminar";

export class SeanceSeminar {
  id_seance_seminar: number;
  ui_id: string;
  id_seminar: string;
  id_seminar_session: number;
  start_time: string;
  end_time: string;
  start_date: string;
  end_date: string;
  date: string;
  name: string;

  seminar: Seminar;

}
