import {Component, OnInit} from '@angular/core';
import {OptionalUnitService} from "../../shared/services/optional-unit.service";
import {OptionalUnit} from "../../shared/models/optional-unit/optional-unit";
import {TeacherService} from "../../shared/services/teacher.service";
import {StudentFileService} from "../../shared/services/student-file.service";
import {Teacher} from "../../shared/models/Teacher";
import {Level} from "../../shared/models/level";
import {Subscription} from "rxjs/Subscription";
import {ActivatedRoute, Router} from "@angular/router";
import {Domaine} from "../../shared/models/optional-unit/domaine";
import {SharedService} from "../../shared/services/shared.service";

declare let jQuery;
declare let swal;

@Component({
  selector: 'app-edit-optional-unit',
  templateUrl: './edit-optional-unit.component.html',
  styleUrls: ['./edit-optional-unit.component.css']
})
export class EditOptionalUnitComponent implements OnInit {
  busy: Subscription;
  optionalUnit: OptionalUnit;
  levels: Array<Level> = [];
  teachers: Array<Teacher> = [];
  domaines: Domaine[] = [];
  submitted: boolean;

  constructor(private optionalUnitService: OptionalUnitService,
              private teacherService: TeacherService,
              private studentFileService: StudentFileService,
              private sharedService: SharedService,
              private router: Router,
              private  route: ActivatedRoute) {
    this.optionalUnit = new OptionalUnit();
  }

  ngOnInit() {
    this.getAllLevels();
    this.getAllDomaines();
    this.initSelectLevel();
    this.getAllTeachers();
    this.initSelectTeachers();
    const baseContext = this;
    this.route.params.subscribe(
      params => {
        const optionalUnitId = +params["optionalUnitId"];
        if (optionalUnitId) {
          this.optionalUnitService.getOptionalUnitById(optionalUnitId).subscribe(data => {
            baseContext.optionalUnit = data;
            setTimeout(function () {
              baseContext.settingLevelsSelect();
            }, 300);
          });
        }
      });

  }


  settingLevelsSelect() {
    const baseContext = this;
    const selectLevel = jQuery(".select-level");
    const levelIds = baseContext.optionalUnit.levels.map(level => {
      return level.id_level;
    });
    selectLevel.val(levelIds).trigger("change");
  }

  getAllLevels() {
    const baseContext = this;
    this.studentFileService.getAllLevels()
      .subscribe(
        (data) => {
          this.levels = data;
          setTimeout(function () {
            if (baseContext.optionalUnit.levels.length > 0) {
              baseContext.settingLevelsSelect();
            }
          }, 500);
        }
      )
  }

  getAllTeachers() {
    const baseContext = this;
    this.busy = this.teacherService.getAllTeachersByStatus(1)
      .subscribe(
        (data) => {
          this.teachers = data;
          setTimeout(function () {
            if (baseContext.optionalUnit.id_coordinateur > 0) {
              const selectCoordinateur = jQuery(".select-coordinateur");
              selectCoordinateur.val(baseContext.optionalUnit.id_coordinateur + "").trigger("change");
            }
          }, 500);
        }
      )
  }

  initSelectTeachers() {
    const baseContext = this;
    const selectCoordinateur = jQuery('.select-coordinateur');
    selectCoordinateur.select2();
    selectCoordinateur.on("change", function () {
      const selectedCoordinateur = +jQuery(this).val();
      if (selectedCoordinateur > 0) {
        baseContext.optionalUnit.id_coordinateur = selectedCoordinateur;
      }
    });
  }

  initSelectLevel() {
    const selectLevel = jQuery('.select-level');
    selectLevel.select2();
  }

  submitCreateOptionalUnit() {
    console.log(this.optionalUnit);

    this.optionalUnit.levelIds = jQuery('.select-level').val();
    this.submitted = true;
    if (this.optionalUnit.levelIds.length === 0 || !this.optionalUnit.id_coordinateur || !this.optionalUnit.description
      || !this.optionalUnit.objectif || !this.optionalUnit.title) {
      return;
    }
    if (this.optionalUnit.id_optional_unit > 0) {
      this.busy = this.optionalUnitService.updateOptionalUnits(this.optionalUnit).subscribe(data => {
        swal('Succées', 'Unité Optionnelle modifiée avec succées', 'success');
        this.router.navigate(['/optional-unit/list']);
      }, error => {
        swal('Erreur', "Erreur de modification de cette unité optionnelle", 'error');
      })
    } else {
      this.busy = this.optionalUnitService.createOptionalUnits(this.optionalUnit).subscribe(data => {
        swal('Succées', 'Unité Optionnelle ajoutée avec succées', 'success');
        this.router.navigate(['/optional-unit/list']);
      }, error => {
        swal('Erreur', "Erreur d'ajout d'une unité optionnelle", 'error');
      })
    }
  }

  private getAllDomaines() {
    this.sharedService.getAllDomaines()
      .subscribe(
        (data) => {
          this.domaines = data;
        },
        (error) => {

        }
      )
  }
}
