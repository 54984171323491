import {SeanceSeminar} from "./SeanceSeminar";

export class SeminarSession {
  id_seminar_session: number;
  name: string;
  year_university: string;
  start_date: string;
  end_date: string;
  id_level: number;
  seances: SeanceSeminar[] = [];
}
