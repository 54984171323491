import {SeminarType} from "./SeminarType";
import {Discipline} from "./Discipline";
import {Teacher} from "../Teacher";

export class Seminar {
  id_seminar: number;
  title: string;
  code: string;
  objectifs: string;
  coordinateur: number;
  capacity: number;
  id_discipline: number;
  id_seminar_type: number;
  seminar_type: SeminarType;
  discipline: Discipline;
  teacher: Teacher;
}
