import {FormationNiveau} from "./formation_niveau";
import {TypeFormation} from "./type_formation";
import {Teacher} from "./Teacher";

export class Formation {
  id_formation: number;
  label: string;
  description: string;
  duration: number = 1;
  isMemoire: number = 0;
  id_type_formation: number;

  capacite: number;
  id_coordinateur: number; // TeacherId
  niveaux: FormationNiveau[] = [];

  niveauIds: number[] = [];

  type_formation: TypeFormation = new TypeFormation();

  coordinateur: Teacher = new Teacher();
}
