import {RouterModule, Routes} from "@angular/router";
import {NgModule} from "@angular/core";
import {ListTrainingComponent} from "./list-training/list-training.component";
import {ManageTrainingComponent} from "./manage-training/manage-training.component";
import {ListDemandsTrainingComponent} from "./list-demands-training/list-demands-training.component";
import {ListDemandsPerTrainingComponent} from "./list-demands-per-training/list-demands-per-training.component";
import {ListValidatedInscriptionsComponent} from "./list-validated-inscriptions/list-validated-inscriptions.component";
import {ManageSessionPostComponent} from "./manage-session-post/manage-session-post.component";
import {SessionPostFormComponent} from "./session-post-form/session-post-form.component";

export const routes: Routes = [
  {
    path: 'list',
    component: ListTrainingComponent
  }, {
    path: 'add',
    component: ManageTrainingComponent
  },
  {
    path: 'sessions',
    children: [
      {
        path: '',
        component: ManageSessionPostComponent
      },
      {
        path: 'add',
        component: SessionPostFormComponent
      },
      {
        path: ':sessionId',
        children: [
          {
            path: 'clone',
            component: SessionPostFormComponent
          },
          {
            path: 'list-demands',
            component: ListDemandsTrainingComponent
          },
          {
            path: 'edit',
            component: SessionPostFormComponent
          },
          {
            path: 'formations',
            children: [
              {
                path: '',
                component: ListTrainingComponent
              },
              {
                path: ':formationId',
                children: [
                  {
                    path: 'listValidated',
                    component: ListValidatedInscriptionsComponent
                  },
                  {
                    path: 'list',
                    component: ListDemandsPerTrainingComponent
                  }
                ]
              }
            ]
          }
        ]
      }
    ]
  },
  {
    path: ':formationId/edit',
    component: ManageTrainingComponent
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class PostGraduateTrainingRouting {
}
