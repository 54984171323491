import {StorageService} from "app/shared/services/storage.service";
import {Injectable} from "@angular/core";
import {GenericService} from "./generic.service";
import {HttpClient} from "@angular/common/http";
import {catchError} from 'rxjs/operators';
import {StructuredDataType} from "../../manage-student/verification-list-student/verification-list-student.component";
import {ItemExcelUpadteInfo, ItemExcelUpdateLevelInfo} from "../../manage-student/update-student-infos/update-student-infos.component";
import {environment} from "../../../environments/environment";

@Injectable()
export class AdminService extends GenericService {


  constructor(private http: HttpClient, private storageService: StorageService) {
    super();

  }


  startReviewingStudents() {
    const url = environment.baseUrl + '/admin/review/start';
    const headers = this.headers.set("Authorization", "Bearer " + this.storageService.read("admin-token"));
    return this.http.post<any>(url, {}, {
      headers: headers
    })
      .pipe(catchError(this.handleErrors));
  }

  finishReviewingStudents() {
    const url = environment.baseUrl + '/admin/review/finish';
    const headers = this.headers.set("Authorization", "Bearer " + this.storageService.read("admin-token"));
    return this.http.post<any>(url, {}, {
      headers: headers
    })
      .pipe(catchError(this.handleErrors));
  }

  changeSectionStatus(id_student: number, id_section: number, status: number, note?: string) {
    const url = environment.baseUrl + '/admin/student/' + id_student + '/update-status-section';
    const headers = this.headers.set("Authorization", "Bearer " + this.storageService.read("admin-token"));
    return this.http.post<any>(url, {
      id_section: id_section,
      status: status,
      note: note
    }, {
      headers: headers
    })
      .pipe(catchError(this.handleErrors));
  }

  changeSectionTeacherStatus(id_teacher: number, id_section: number, status: number, note?: string) {
    const url = environment.baseUrl + '/admin/teacher/' + id_teacher + 'update-status-section';
    const headers = this.headers.set("Authorization", "Bearer " + this.storageService.read("admin-token"));
    return this.http.post<any>(url, {
      id_section: id_section,
      status: status,
      note: note
    }, {
      headers: headers
    })
      .pipe(catchError(this.handleErrors));
  }

  getListInscritStudents() {
    const url = environment.baseUrl + '/admin/registration/university-year/list-student';
    const headers = this.headers.set("Authorization", "Bearer " + this.storageService.read("admin-token"));
    return this.http.get<any>(url, {
      headers: headers
    })
      .pipe(catchError(this.handleErrors));
  }

  getListInscritStudentsByLevel(levelId) {
    const url = environment.baseUrl + '/admin/registration/university-year/list-student/level/' + levelId;
    const headers = this.headers.set("Authorization", "Bearer " + this.storageService.read("admin-token"));
    return this.http.get<any>(url, {
      headers: headers
    })
      .pipe(catchError(this.handleErrors));
  }

  generatePDFStudent(studentId: number) {
    const headers = this.headers.set("Authorization", "Bearer " + this.storageService.read("admin-token"));
    const url = environment.baseUrl + "/admin/student/" + studentId + "/generateDetailPDF";
    const options = {
      headers: headers,
      responseType: 'blob' as 'json'
    };
    return this.http.get<any>(url, options).pipe(catchError(this.handleErrors));
  }


  getAdminByPrivileges(privilege_id: number) {
    const headers = this.headers.set("Authorization", "Bearer " + this.storageService.read("admin-token"));
    const url = environment.baseUrl + "/admin/privilege/" + privilege_id;

    return this.http.get<any>(url, {
      headers: headers
    })
      .pipe(catchError(this.handleErrors));
  }

  generateStudentsExcel() {
    const headers = this.headers.set("Authorization", "Bearer " + this.storageService.read("admin-token"));
    const url = environment.baseUrl + "/admin/student/excel";
    const options = {
      headers: headers,
      responseType: 'blob' as 'json'
    };
    return this.http.get<any>(url, options)
      .pipe(catchError(this.handleErrors));
  }


  affectScholarToStudent(studentId: number, adminId: number) {
    const headers = this.headers.set("Authorization", "Bearer " + this.storageService.read("admin-token"));
    const url = environment.baseUrl + "/admin/student/" + studentId + "/affectScholar/" + adminId;
    return this.http.post<any>(url, {}, {
      headers: headers,
    })
      .pipe(catchError(this.handleErrors));
  }

  changeSectionStatusTeacher(id_Teacher: number, id_Section: number, status: number, note: string) {
    const url = environment.baseUrl + '/admin/teacher/' + id_Teacher + '/update-status-section';
    const headers = this.headers.set("Authorization", "Bearer " + this.storageService.read("admin-token"));
    return this.http.post<any>(url, {
      id_section: id_Section,
      status: status,
      note: note
    }, {
      headers: headers
    })
      .pipe(catchError(this.handleErrors));
  }

  getListInscritbyLevelExcel(yearUniversity: string, selectedLevel: number) {
    const headers = this.headers.set("Authorization", "Bearer " + this.storageService.read("admin-token"));
    const url = environment.baseUrl + "/admin/registration/university-year/list-student/level/" + selectedLevel + "/excel";
    const options = {
      headers: headers,
      responseType: 'blob' as 'json'
    };
    return this.http.post<any>(url, {
      year_university: yearUniversity
    }, options)
      .pipe(catchError(this.handleErrors));
  }

  generateListStudentProblem(data: StructuredDataType[]) {
    const headers = this.headers.set("Authorization", "Bearer " + this.storageService.read("admin-token"));
    const url = environment.baseUrl + "/admin/generateListProblemStudentExcel";
    const options = {
      headers: headers,
      responseType: 'blob' as 'json'
    };
    return this.http.post<any>(url, JSON.stringify(data),
      options
    )
      .pipe(catchError(this.handleErrors));
  }

  generateStudentFile(selectedLevel: number) {
    const headers = this.headers.set("Authorization", "Bearer " + this.storageService.read("admin-token"));
    const url = environment.baseUrl + "/admin/student/student-files-level/" + selectedLevel;
    const options = {
      headers: headers,
      responseType: 'blob' as 'json'
    };
    return this.http.get<any>(url, options
    )
      .pipe(catchError(this.handleErrors));
  }

  updateStudentInfo(levelId: number, finalData: ItemExcelUpadteInfo[]) {
    const headers = this.headers.set("Authorization", "Bearer " + this.storageService.read("admin-token"));
    const url = environment.baseUrl + "/admin/student/update-infos/" + levelId;
    const options = {
      headers: headers
    };
    return this.http.post<any>(url, finalData, options
    )
      .pipe(catchError(this.handleErrors));
  }

  updateStudentLevelInfo(finalDataLevel: ItemExcelUpdateLevelInfo[]) {
    const headers = this.headers.set("Authorization", "Bearer " + this.storageService.read("admin-token"));
    const url = environment.baseUrl + "/admin/student/update-level-infos";
    const options = {
      headers: headers
    };
    return this.http.post<any>(url, finalDataLevel, options
    )
      .pipe(catchError(this.handleErrors));
  }


  downloadStudentsFileByIds(studentIds: number[]) {
    const headers = this.headers.set("Authorization", "Bearer " + this.storageService.read("admin-token"));
    const url = environment.baseUrl + "/admin/student/downloadfiles";
    const options = {
      headers: headers,
      responseType: 'blob' as 'json'
    };
    return this.http.post<any>(url, studentIds, options
    )
      .pipe(catchError(this.handleErrors));
  }

  generateStudentFileByIds(studentIds: number[]) {
    const headers = this.headers.set("Authorization", "Bearer " + this.storageService.read("admin-token"));
    const url = environment.baseUrl + "/admin/student/student-file-ids";
    const options = {
      headers: headers,
      responseType: 'blob' as 'json'
    };
    return this.http.post<any>(url, studentIds, options
    )
      .pipe(catchError(this.handleErrors));
  }
}
