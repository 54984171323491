import {Injectable} from "@angular/core";
import {GenericService} from "./generic.service";
import {HttpClient} from "@angular/common/http";
import {StorageService} from "./storage.service";
import {catchError} from "rxjs/operators";
import {Observable} from "rxjs/Observable";
import {environment} from "../../../environments/environment";
import {Seminar} from "../models/mseminar/Seminar";
import {SeminarSession} from "../models/mseminar/SeminarSession";

@Injectable()
export class MseminarService extends GenericService {

  baseUrlSeminar = environment.baseUrl + '/admin/seminar';

  baseUrlSessionSeminar = environment.baseUrl + '/admin/seminar-session';

  constructor(private http: HttpClient, private storageService: StorageService) {
    super();
  }

  getAllSeminars(): Observable<Array<Seminar>> {
    const headers = this.headers.set("Authorization", "Bearer " + this.storageService.read("admin-token"));
    return this.http.get(this.baseUrlSeminar, {headers: headers})
      .pipe(catchError(this.handleErrors)) as Observable<Array<Seminar>>;
  }

  createSeminar(seminar: Seminar): Observable<Seminar> {
    const headers = this.headers.set("Authorization", "Bearer " + this.storageService.read("admin-token"));
    return this.http.post(this.baseUrlSeminar, seminar, {headers: headers})
      .pipe(catchError(this.handleErrors)) as Observable<Seminar>;
  }


  deleteSeminar(seminarId: number) {
    const headers = this.headers.set("Authorization", "Bearer " + this.storageService.read("admin-token"));
    return this.http.delete(this.baseUrlSeminar + "/" + seminarId, {headers: headers})
      .pipe(catchError(this.handleErrors));
  }


  getAllSeminarTypes() {
    const headers = this.headers.set("Authorization", "Bearer " + this.storageService.read("admin-token"));
    return this.http.get(environment.baseUrl + "/seminarTypes",
      {headers: headers})
      .pipe(catchError(this.handleErrors));
  }

  getSeminarById(seminarId: number) {
    const headers = this.headers.set("Authorization", "Bearer " + this.storageService.read("admin-token"));
    return this.http.get(this.baseUrlSeminar + "/" + seminarId,
      {headers: headers})
      .pipe(catchError(this.handleErrors));
  }

  updateSeminar(seminar: Seminar) {
    const headers = this.headers.set("Authorization", "Bearer " + this.storageService.read("admin-token"));
    return this.http.put(this.baseUrlSeminar + "/" + seminar.id_seminar, seminar,
      {headers: headers})
      .pipe(catchError(this.handleErrors));
  }

  getAllSessionsSeminar() {
    const headers = this.headers.set("Authorization", "Bearer " + this.storageService.read("admin-token"));
    return this.http.get(this.baseUrlSessionSeminar,
      {headers: headers})
      .pipe(catchError(this.handleErrors));
  }

  addSession(session: SeminarSession) {
    const headers = this.headers.set("Authorization", "Bearer " + this.storageService.read("admin-token"));
    return this.http.post(this.baseUrlSessionSeminar, session,
      {headers: headers})
      .pipe(catchError(this.handleErrors));
  }

  deleteSeminarSession(id_seminar_session: number) {
    const headers = this.headers.set("Authorization", "Bearer " + this.storageService.read("admin-token"));
    return this.http.delete(this.baseUrlSessionSeminar + "/" + id_seminar_session, {headers: headers})
      .pipe(catchError(this.handleErrors));
  }

  getSeminarSessionById(seminarSessionId: number) {
    const headers = this.headers.set("Authorization", "Bearer " + this.storageService.read("admin-token"));
    return this.http.get(this.baseUrlSessionSeminar + "/" + seminarSessionId,
      {headers: headers})
      .pipe(catchError(this.handleErrors));
  }

  updateSession(session: SeminarSession) {
    const headers = this.headers.set("Authorization", "Bearer " + this.storageService.read("admin-token"));
    return this.http.put(this.baseUrlSessionSeminar + "/" + session.id_seminar_session, session,
      {headers: headers})
      .pipe(catchError(this.handleErrors));
  }

  getAllInscriptions(id_seminar_session: number) {
    const headers = this.headers.set("Authorization", "Bearer " + this.storageService.read("admin-token"));
    return this.http.get(this.baseUrlSessionSeminar + "/" + id_seminar_session + "/list-inscrit",
      {headers: headers})
      .pipe(catchError(this.handleErrors));
  }
}
