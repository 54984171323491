import {Component, OnInit} from '@angular/core';
import {SessionChoixOU} from "../../shared/models/optional-unit/session-choix-ou";
import {ActivatedRoute} from "@angular/router";
import {OptionalUnitService} from "../../shared/services/optional-unit.service";
import {Subscription} from "rxjs";
import {Student} from "../../shared/models/student";
import {StudentService} from "../../shared/services/student.service";
import {OptionalUnit} from "../../shared/models/optional-unit/optional-unit";

declare var swal: any;

@Component({
  selector: 'app-manuel-affecation-ou',
  templateUrl: './manuel-affecation-ou.component.html',
  styleUrls: ['./manuel-affecation-ou.component.css']
})
export class ManuelAffecationOUComponent implements OnInit {

  sessionChoice: SessionChoixOU = new SessionChoixOU();
  busy: Subscription;


  submitted: boolean;
  cin: string;

  selectedStudent: Student;

  constructor(private route: ActivatedRoute,
              private optionalUnitService: OptionalUnitService,
              private studentServices: StudentService) {

    this.selectedStudent = new Student();
  }

  ngOnInit() {
    const baseContext = this;
    const sessionChoiceId = parseInt(this.route.snapshot.paramMap.get('sessionChoiceId'), 0);

    this.busy = this.optionalUnitService.getSessionOptionalUnitById(sessionChoiceId)
      .subscribe(
        (data: SessionChoixOU) => {
          baseContext.sessionChoice = data;
        });
  }

  selectStudent() {
    this.submitted = true;

    if (!this.cin) {
      return;
    }
    this.busy = this.studentServices.getStudentChoicesUO(this.cin, this.sessionChoice.id_session_choix_ou)
      .subscribe(
        (data) => {
          this.selectedStudent = data;
        },
        (error) => {
          if (error.status === 403) {
            swal('Erreur', "Etudiant pas inscrit dans l'année universitaire", 'error');
          }
          if (error.status === 405) {
            swal('Erreur', "Etudiant n'existe pas dans cette session, vérifier le niveau", "error");
          }
          if (error.status === 404) {
            swal('Erreur', "Etudiant n'existe pas dans la plateforme, vérifier l'identifiant", "error");
          }
        }
      )
  }

  onCodeUnitChange(optionalId: string, index: number) {
    if (this.selectedStudent.optional_unit_choices[index].id_optional_unit !== parseInt(optionalId, 0)) {
      const indexOptionalUnit = this.sessionChoice.optional_units.map(
        item => {
          return item.id_optional_unit
        }
      ).indexOf(parseInt(optionalId, 0));

      this.selectedStudent.optional_unit_choices[index].domaine = this.sessionChoice.optional_units[indexOptionalUnit].domaine;
      this.selectedStudent.optional_unit_choices[index].title = this.sessionChoice.optional_units[indexOptionalUnit].title;
    }
  }

  removeUO(index: number) {
    this.selectedStudent.optional_unit_choices.splice(index, 1);
  }

  addUO() {

    if (!this.isValidUO()) {
      return;
    }
    this.selectedStudent.optional_unit_choices.push(new OptionalUnit());
  }

  inscriptionStudentUO() {

    if (!this.selectedStudent.id_student) {
      swal('Warning', "Il faut séléctionner un étudiant", "warning");
    }

    if (!this.isValidUO()) {
      swal("Warning", "Valider vos champs", "warning");
      return
    }

    this.busy = this.optionalUnitService.affectStudentOU(this.sessionChoice.id_session_choix_ou, this.selectedStudent.optional_unit_choices, this.selectedStudent.id_student)
      .subscribe(
        (data) => {
          swal("Succées", "Affectation avec succées", "success");
        },
        (error) => {

        }
      )
  }

  private isValidUO() {

    for (let i = 0; i < this.selectedStudent.optional_unit_choices.length; i++) {
      let optionalUnit = this.selectedStudent.optional_unit_choices[i];
      if (!optionalUnit.id_optional_unit) {
        return false;
      }
    }
    return true;
  }
}
