/**
 * Created by Abbes on 04/08/2017.
 */
import {City} from "./city";

export class Study {
  public id_study: number;
  public year: string;
  public id_result: number;
  public id_student: number;
  public id_level: number;
  public id_university: number;
  public id_credit: number;
  public medias: Array<any>;
  public city_university: City = new City();
  public label_institution: string;
  public label_university: string;
  id_specialite: number;
}
