import {Component, OnInit} from "@angular/core";
import {ActivatedRoute} from "@angular/router";
import {Student} from "../../shared/models/student";
import {Subscription} from "rxjs/Subscription";
import {StudentService} from "../../shared/services/student.service";
import {AdminService} from "../../shared/services/admin.service";
import * as FileSaver from "file-saver";
import {environment} from "../../../environments/environment";

@Component({
  selector: 'app-detail-student',
  templateUrl: './detail-student.component.html',
  styleUrls: ['./detail-student.component.css']
})
export class DetailStudentComponent implements OnInit {

  student: Student = new Student();
  busy: Subscription;
  baseUrl: string;

  constructor(private  route: ActivatedRoute, private studentService: StudentService, private adminService: AdminService) {
    this.student = new Student();
  }

  ngOnInit() {
    const baseContext = this;
    baseContext.baseUrl = environment.baseUrl;
    this.route.params.subscribe(
      params => {
        this.student.id_student = +params["studentId"];
        this.studentService.getStudentDetail(this.student.id_student).subscribe(data => {
          this.student = data;
        });
      });
  }

  generatePDF(studentId: number) {
    this.adminService.generatePDFStudent(studentId)
      .subscribe(
        (data) => {
          FileSaver.saveAs(data, this.student.first_name + " " + this.student.last_name + "_Dossier.pdf");
        }
      )
  }

}
