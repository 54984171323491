import {Injectable} from "@angular/core";
import {GenericService} from "./generic.service";
import {HttpClient} from "@angular/common/http";
import {StorageService} from "./storage.service";
import {catchError} from "rxjs/operators";
import {OptionalUnit} from "../models/optional-unit/optional-unit";
import {Observable} from "rxjs/Observable";
import {Student} from "../models/student";
import {SessionChoixOU} from "../models/optional-unit/session-choix-ou";
import {environment} from "../../../environments/environment";

@Injectable()
export class OptionalUnitService extends GenericService {

  baseUrl = environment.baseUrl + '/admin/optional-unit';

  constructor(private http: HttpClient, private storageService: StorageService) {
    super();
  }

  getAllOptionalUnits(): Observable<Array<OptionalUnit>> {
    const headers = this.headers.set("Authorization", "Bearer " + this.storageService.read("admin-token"));
    return this.http.get(this.baseUrl, {headers: headers})
      .pipe(catchError(this.handleErrors)) as Observable<Array<OptionalUnit>>;
  }

  createOptionalUnits(optionalUnit: OptionalUnit): Observable<OptionalUnit> {
    const headers = this.headers.set("Authorization", "Bearer " + this.storageService.read("admin-token"));
    return this.http.post(this.baseUrl, optionalUnit, {headers: headers})
      .pipe(catchError(this.handleErrors)) as Observable<OptionalUnit>;
  }

  updateOptionalUnits(optionalUnit: OptionalUnit): Observable<OptionalUnit> {
    const headers = this.headers.set("Authorization", "Bearer " + this.storageService.read("admin-token"));
    return this.http.put(this.baseUrl + "/" + optionalUnit.id_optional_unit, optionalUnit, {headers: headers})
      .pipe(catchError(this.handleErrors)) as Observable<OptionalUnit>;
  }

  getOptionalUnitById(idOptionalId: number) {
    const headers = this.headers.set("Authorization", "Bearer " + this.storageService.read("admin-token"));
    return this.http.get(this.baseUrl + "/" + idOptionalId, {headers: headers})
      .pipe(catchError(this.handleErrors)) as Observable<OptionalUnit>;
  }

  getOptionalUnitByLevel(levelId: number) {
    const headers = this.headers.set("Authorization", "Bearer " + this.storageService.read("admin-token"));
    return this.http.get(this.baseUrl + '/level/' + levelId, {headers: headers})
      .pipe(catchError(this.handleErrors))as Observable<Array<OptionalUnit>>
  }

  deleteOptionalUnit(optionalUnit: OptionalUnit) {
    const headers = this.headers.set("Authorization", "Bearer " + this.storageService.read("admin-token"));
    return this.http.delete(this.baseUrl + "/" + optionalUnit.id_optional_unit, {headers: headers})
      .pipe(catchError(this.handleErrors));
  }

  getAllStudentRegistredOptionalUnitByLevel(levelId: number): Observable<Array<Student>> {
    const headers = this.headers.set("Authorization", "Bearer " + this.storageService.read("admin-token"));
    return this.http.get(this.baseUrl + '/registration/level/' + levelId, {headers: headers})
      .pipe(catchError(this.handleErrors)) as Observable<Array<Student>>;
  }

  sendEmailToAllNonRegistredStudents(levelId: number) {
    const headers = this.headers.set("Authorization", "Bearer " + this.storageService.read("admin-token"));
    return this.http.post(this.baseUrl + '/registration/level/' + levelId + '/sendReminderEmail', {}, {headers: headers})
      .pipe(catchError(this.handleErrors))
  }

  affectStudentsToUO(levelId = null) {
    const headers = this.headers.set("Authorization", "Bearer " + this.storageService.read("admin-token"));
    return this.http.post(this.baseUrl + '/registration/level/' + levelId + '/affectStudentsToUO', {}, {headers: headers})
      .pipe(catchError(this.handleErrors))
  }


  getSessionOptionalUnitByYearUniversity(year_university: string) {
    const url = environment.baseUrl + '/student/basic_training/session-optional-unit/year-university';
    const headers = this.headers.set("Authorization", "Bearer " + this.storageService.read('admin-token'));
    return this.http.post(url, {
        year_university: year_university
      }, {
        headers: headers
      }
    ).pipe(catchError

      (
        this
          .handleErrors
      )
    )
  }

  getAllSessionOptionalUnits() {
    const headers = this.headers.set("Authorization", "Bearer " + this.storageService.read('admin-token'));
    return this.http.get(this.baseUrl + "/session-choice", {
        headers: headers
      }
    ).pipe(catchError
      (
        this
          .handleErrors
      )
    )
  }

  deleteSessionChoiceById(sessionChoiceId: number) {
    const headers = this.headers.set("Authorization", "Bearer " + this.storageService.read("admin-token"));
    return this.http.delete(this.baseUrl + "/session-choice/" + sessionChoiceId,
      {headers: headers})
      .pipe(catchError(this.handleErrors));

  }

  addSessionChoice(sessionChoice: SessionChoixOU) {
    const headers = this.headers.set("Authorization", "Bearer " + this.storageService.read("admin-token"));
    return this.http.post(this.baseUrl + "/session-choice", sessionChoice, {headers: headers})
      .pipe(catchError(this.handleErrors)) as Observable<SessionChoixOU>;
  }

  getSessionOptionalUnitById(sessionChoiceId: number) {
    const headers = this.headers.set("Authorization", "Bearer " + this.storageService.read("admin-token"));
    return this.http.get(this.baseUrl + "/session-choice/" + sessionChoiceId,
      {headers: headers})
      .pipe(catchError(this.handleErrors));
  }

  editSessionChoice(sessionChoice: SessionChoixOU) {
    const headers = this.headers.set("Authorization", "Bearer " + this.storageService.read("admin-token"));
    return this.http.put(this.baseUrl + "/session-choice/" + sessionChoice.id_session_choix_ou, sessionChoice,
      {headers: headers})
      .pipe(catchError(this.handleErrors)) as Observable<SessionChoixOU>;
  }

  getListAffectationBySession(sessionChoiceId: number) {
    const headers = this.headers.set("Authorization", "Bearer " + this.storageService.read("admin-token"));
    return this.http.get(this.baseUrl + "/session-choice/" + sessionChoiceId + "/affectations",
      {headers: headers})
      .pipe(catchError(this.handleErrors));
  }

  autoAffectation(sessionChoiceId: number) {
    const headers = this.headers.set("Authorization", "Bearer " + this.storageService.read("admin-token"));
    return this.http.get(this.baseUrl + "/session-choice/" + sessionChoiceId + "/auto-affectations",
      {headers: headers})
      .pipe(catchError(this.handleErrors));
  }

  affectStudentOU(sessionChoiceId: number, choices: OptionalUnit[], studentId) {
    const headers = this.headers.set("Authorization", "Bearer " + this.storageService.read("admin-token"));
    const url = this.baseUrl + "/session-choice/" + sessionChoiceId + "/affect-student";

    return this.http.post<any>(url, {
      choices: choices,
      studentId: studentId
    }, {
      headers: headers
    }).pipe(
      catchError(this.handleErrors)
    )
  }
}
