import {Component, OnInit} from '@angular/core';
import {Subscription} from "rxjs";
import {MseminarService} from "../../shared/services/mseminar.service";
import {Seminar} from "../../shared/models/mseminar/Seminar";
import {Discipline} from "../../shared/models/mseminar/Discipline";
import {SharedService} from "../../shared/services/shared.service";
import {TeacherService} from "../../shared/services/teacher.service";
import {Teacher} from "../../shared/models/Teacher";
import {ActivatedRoute, Router} from "@angular/router";
import {SeminarType} from "../../shared/models/mseminar/SeminarType";

declare var swal: any;

@Component({
  selector: 'app-form-seminar',
  templateUrl: './form-seminar.component.html',
  styleUrls: ['./form-seminar.component.css']
})
export class FormSeminarComponent implements OnInit {

  busy: Subscription;
  seminar: Seminar;
  submitted: boolean;

  teachers: Teacher[] = [];
  disciplines: Discipline[] = [];

  seminarTypes: SeminarType[] = [];

  seminarId: number;


  constructor(private mseminarService: MseminarService,
              private sharedService: SharedService,
              private teacherService: TeacherService,
              private router: Router,
              private route: ActivatedRoute) {
    this.seminar = new Seminar();
    this.seminarId = parseInt(this.route.snapshot.paramMap.get('seminarId'), 0);
  }

  ngOnInit() {

    this.getAllDisciplines();
    this.getAllTeachers();
    this.getAllSeminarTypes();

    if (this.seminarId) {
      this.getSeminarById(this.seminarId);
    }

  }


  getAllSeminarTypes() {
    this.busy = this.mseminarService.getAllSeminarTypes()
      .subscribe(
        (data: SeminarType[]) => {
          this.seminarTypes = data;
        },
        (error) => {

        }
      )
  }

  getAllTeachers() {
    this.busy = this.teacherService.getAllTeachersByStatus(1)
      .subscribe(
        (data) => {
          this.teachers = data;
        }
      )
  }

  getAllDisciplines() {
    this.busy = this.sharedService.getAllDisciplines()
      .subscribe(
        (data) => {
          this.disciplines = data;
        },
        (error) => {

        }
      )
  }

  isValidChamps() {
    return !(!this.seminar.title || !this.seminar.coordinateur || !this.seminar.objectifs || !this.seminar.id_seminar_type
      || !this.seminar.id_discipline || !this.seminar.capacity);

  }

  submitForm() {
    this.submitted = true;

    if (!this.isValidChamps()) {
      return;
    }

    if (!this.seminar.id_seminar) {
      this.busy = this.mseminarService.createSeminar(this.seminar).subscribe(data => {
        swal('Succées', 'Mini Séminaire ajoutée avec succées', 'success');
        this.router.navigate(['/mini-seminar/list']);
      }, error => {
        swal('Erreur', "Erreur d'ajout d'une mini séminaire", 'error');
      })
    } else {
      this.busy = this.mseminarService.updateSeminar(this.seminar).subscribe(
        (data) => {
          swal("Succées", "Mini Séminaire updated avec succées", "success");
          this.router.navigate(['/mini-seminar/list']);
        },
        (error) => {
          swal("Erreur", "Erreur d'ajout d'une mini séminaire", "error");
        }
      )
    }
  }


  private getSeminarById(seminarId: number) {
    this.busy = this.mseminarService.getSeminarById(seminarId)
      .subscribe(
        (data: Seminar) => {
          this.seminar = data;
        }
      )
  }
}
