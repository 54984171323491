/**
 * Created by Abbes on 25/08/2017.
 */
import {Component, OnInit} from "@angular/core";
import {Utils} from "../../shared/utils";
import {RegistrationYearUniversity} from "../../shared/models/RegistrationYearUniversity";
import {InscriptionService} from "../../shared/services/inscription.service";
import {Subscription} from "rxjs/Subscription";
import {SessionFormation} from "../../shared/models/session_formation";
import {PostGraduateTrainingService} from "../../shared/services/post-graduate-training.service";
import {OptionalUnitService} from "../../shared/services/optional-unit.service";

declare var swal;
declare var jQuery;

@Component({
  templateUrl: 'inscription-year-university.component.html',
  styleUrls: [],

})
export class InscriptionYearUniversityComponent implements OnInit {


  busy: Subscription;
  submitted: boolean;
  registrationYearPDCEM: RegistrationYearUniversity = new RegistrationYearUniversity(1);
  registrationYearIRESIDANT: RegistrationYearUniversity = new RegistrationYearUniversity(2);
  sessionFormation: SessionFormation = new SessionFormation();
  editActionPDCEM: boolean;
  editActionIRESIDANT: boolean;

  // sessionOptionalUnit: SessionOptionalUnit = new SessionOptionalUnit();

  constructor(private inscriptionService: InscriptionService, private postGraduatedTraining: PostGraduateTrainingService,
              private optionalUnitService: OptionalUnitService) {

  }

  ngOnInit() {
    this.registrationYearPDCEM.year_university = Utils.getCurrentUniversityYear(1);
    this.registrationYearIRESIDANT.year_university = Utils.getCurrentUniversityYear(2);
    this.sessionFormation.year_university = Utils.getCurrentUniversityYear(1);
    // this.sessionOptionalUnit.year_university = Utils.getCurrentUniversityYear(1);

    this.getCurrentInscription(1);
    this.getCurrentInscription(2);
    this.getCurrentSessionFormation();
    // this.getCurrentSessionOptionalUnit();
    this.initializeDates();

  }

  modifyDateChoicesPostFormation() {
    this.submitted = true;

    this.busy = this.postGraduatedTraining.modifyDateChoicesPOSTFormation(this.sessionFormation)
      .subscribe(
        (data) => {
          swal('Succées', 'Modification des dates de choix est éffectuée avec succées', 'success');
        },
        (error) => {

        }
      )
  }

  /*modifyDateChoicesOptionalUnit() {
    this.submitted = true;
    this.busy = this.optionalUnitService.modifyDateChoicesOptionalUnit(this.sessionOptionalUnit)
      .subscribe(
        (data) => {
          swal("Succées", "Modification des dates de choix est éffectuée avec succées", "success");
        },
        (error) => {

        }
      )
  }*/

  openInscription(cycle: number) {

    this.submitted = true;
    let registrationYear: RegistrationYearUniversity;
    registrationYear = cycle === 1 ? this.registrationYearPDCEM : this.registrationYearIRESIDANT;

    if (!registrationYear.start_date || !registrationYear.end_date) {
      return;
    }
    this.busy = this.inscriptionService.openInscription(registrationYear)
      .subscribe(
        (data) => {
          swal({
            title: "Succès!",
            text: "Inscription de l'année universitaire est crée avec succées",
            confirmButtonColor: "#66BB6A",
            type: "success"
          });
        },
        (error) => {
        }
      )
  }


  private getCurrentSessionFormation() {
    this.busy = this.postGraduatedTraining.getSessionFormationByYearUniversity(this.sessionFormation.year_university)
      .subscribe(
        (data: SessionFormation) => {
          this.sessionFormation = data;
          this.updateChangesDates(3);
        },
        (error) => {

        }
      )
  }

  /*private getCurrentSessionOptionalUnit() {
    this.busy = this.optionalUnitService.getSessionOptionalUnitByYearUniversity(this.sessionOptionalUnit.year_university)
      .subscribe(
        (data: SessionOptionalUnit) => {
          this.sessionOptionalUnit = data;
          this.updateChangesDates(4);
        },
        (error) => {

        }
      )
  }*/

  private getCurrentInscription(cycle: number) {
    const registrationYear = cycle === 1 ? this.registrationYearPDCEM : this.registrationYearIRESIDANT;
    this.busy = this.inscriptionService.getCurrentInscription(registrationYear.year_university, cycle)
      .subscribe(
        (data) => {
          console.log(data);
          if (cycle === 1) {
            console.log(data);
            this.registrationYearPDCEM = Object.keys(data).length === 0 ? new RegistrationYearUniversity(1) : data;
            this.editActionPDCEM = Object.keys(data).length !== 0;

            if (!this.editActionPDCEM) {
              this.registrationYearPDCEM.year_university = Utils.getCurrentUniversityYear(1);
            } else {
              this.updateChangesDates(1);
            }
          } else {
            this.registrationYearIRESIDANT = Object.keys(data).length === 0 ? new RegistrationYearUniversity(2) : data;
            this.editActionIRESIDANT = Object.keys(data).length !== 0;

            if (!this.editActionIRESIDANT) {
              this.registrationYearIRESIDANT.year_university = Utils.getCurrentUniversityYear(2);
            } else {
              this.updateChangesDates(2);
            }
          }


        },
        (error) => {

        }
      )
  }

  private initializeDates() {
    const baseContext = this;

    this.initItemDate('.date_open_PDCEM');
    this.initItemDate('.date_close_PDCEM');
    this.initItemDate('.date_open_IRESIDANT');
    this.initItemDate('.date_close_IRESIDANT');
    this.initItemDate('.date_open_POST_Formation');
    this.initItemDate('.date_close_POST_Formation');
    // this.initItemDate('.date_open_optional_unit');
    // this.initItemDate('.date_close_optional_unit');
  }

  private updateChangesDates(typeInscription: number) {


    switch (typeInscription) {
      case 1 : {
        /* Start Date */
        const startDate = jQuery('.date_open_PDCEM');
        startDate.val(Utils.convertDate(this.registrationYearPDCEM.start_date)).trigger("change");
        /* End Date */
        const endDate = jQuery('.date_close_PDCEM');
        endDate.val(Utils.convertDate(this.registrationYearPDCEM.end_date)).trigger("change");
        break;
      }
      case 2 : {
        /* Start Date */
        const startDate = jQuery('.date_open_IRESIDANT');
        startDate.val(Utils.convertDate(this.registrationYearIRESIDANT.start_date)).trigger("change");
        /* End Date */
        const endDate = jQuery('.date_close_IRESIDANT');
        endDate.val(Utils.convertDate(this.registrationYearIRESIDANT.end_date)).trigger("change");
        break;
      }
      case 3 : {
        /* Start Date */
        const startDate = jQuery('.date_open_POST_Formation');
        startDate.val(Utils.convertDate(this.sessionFormation.start_date_choice)).trigger("change");
        /* End Date */
        const endDate = jQuery('.date_close_POST_Formation');
        endDate.val(Utils.convertDate(this.sessionFormation.end_date_choice)).trigger("change");
        break;
      }
      /*case 4 : {
        const startDate = jQuery('.date_open_optional_unit');
        startDate.val(Utils.convertDate(this.sessionOptionalUnit.start_date_choice)).trigger("change");
        const endDate = jQuery('.date_close_optional_unit');
        endDate.val(Utils.convertDate(this.sessionOptionalUnit.end_date_choice)).trigger("change");
        break;
      }*/
    }
  }

  private initItemDate(className: string) {
    const date = jQuery(className);
    const baseContext = this;
    let format = "DD/MM/YYYY";
    format = className.indexOf('optional_unit') !== -1 || className.indexOf('POST_Formation') !== -1 ? format + " HH:mm" : format;
    date.daterangepicker({
      "singleDatePicker": true,
      "timePicker": className.indexOf('optional_unit') !== -1 || className.indexOf('POST_Formation') !== -1,
      "timePicker24Hour": true,
      "locale": {
        "format": format
      }
    });

    date.on("change", function () {
      switch (className) {
        case '.date_open_PDCEM' :
          baseContext.registrationYearPDCEM.start_date = Utils.convertDateServer(date.val());
          break;
        case '.date_close_PDCEM':
          baseContext.registrationYearPDCEM.end_date = Utils.convertDateServer(date.val());
          break;
        case '.date_open_IRESIDANT' :
          baseContext.registrationYearIRESIDANT.start_date = Utils.convertDateServer(date.val());
          break;
        case '.date_close_IRESIDANT' :
          baseContext.registrationYearIRESIDANT.end_date = Utils.convertDateServer(date.val());
          break;
        case '.date_open_POST_Formation' :
          baseContext.sessionFormation.start_date_choice = Utils.convertDateServer(date.val());
          break;
        case '.date_close_POST_Formation' :
          baseContext.sessionFormation.end_date_choice = Utils.convertDateServer(date.val());
          break;

        /*case '.date_open_optional_unit' :
          baseContext.sessionOptionalUnit.start_date_choice = Utils.convertDateServer(date.val());
          break;
        case '.date_close_optional_unit' :
          baseContext.sessionOptionalUnit.end_date_choice = Utils.convertDateServer(date.val());
          break;*/
      }

    });
  }
}
