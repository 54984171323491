import {RouterModule, Routes} from "@angular/router";
import {NgModule} from "@angular/core";
import {ListOptionalUnitComponent} from "./list-optional-unit/list-optional-unit.component";
import {EditOptionalUnitComponent} from "./edit-optional-unit/edit-optional-unit.component";
import {ListSessionOuComponent} from "./list-session-ou/list-session-ou.component";
import {EditSessionOuComponent} from "./edit-session-ou/edit-session-ou.component";
import {ManuelAffecationOUComponent} from "./manuel-affecation-ou/manuel-affecation-ou.component";

export const routes: Routes = [
    {
      path: 'list',
      component: ListOptionalUnitComponent,
    }, {
      path: 'add',
      component: EditOptionalUnitComponent,
    },
    {
      path: 'sessions',
      component: ListSessionOuComponent
    },
    {
      path: 'add-session',
      component: EditSessionOuComponent
    },
    {
      path: 'edit-session/:sessionChoiceId',
      component: EditSessionOuComponent
    },
    {
      path: ':optionalUnitId/edit',
      component: EditOptionalUnitComponent,
    },
    {
      path: 'manuel-affectation/:sessionChoiceId',
      component : ManuelAffecationOUComponent
    }

  ]
;

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class OptionalUnitRoutingModule {
}
