import {Country} from "./country";
/**
 * Created by Abbes on 10/07/2017.
 */
export class City {
  public id_City: number;
  public Name: string;
  public CountryCode: number;
  public country: Country;
}
