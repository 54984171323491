import {Component, OnInit} from '@angular/core';
import {OptionalUnitService} from "../../shared/services/optional-unit.service";
import {Subscription} from "rxjs";
import {Utils} from "../../shared/utils";
import {SessionChoixOU} from "../../shared/models/optional-unit/session-choix-ou";
import {StudentChoixOu} from "../../shared/models/optional-unit/student-choix-ou";
import * as XLSX from "xlsx";

declare var swal: any;

@Component({
  selector: 'app-list-session-ou',
  templateUrl: './list-session-ou.component.html',
  styleUrls: ['./list-session-ou.component.css']
})
export class ListSessionOuComponent implements OnInit {


  sessions: SessionChoixOU [] = [];
  busy: Subscription;

  contentSheet: string[][] = [['NUM', 'NOM', 'PRENOM', 'CIN/IDENT']];

  constructor(private optionalUnitService: OptionalUnitService) {
  }

  ngOnInit() {
    this.loadSessions();
  }

  loadSessions() {
    this.busy = this.optionalUnitService.getAllSessionOptionalUnits()
      .subscribe(
        (data: SessionChoixOU[]) => {
          this.sessions = data;
          Utils.initializeDataTables("sessions-choix-ou-table", 15, 6);
        },
        (error) => {
        }
      )
  }

  deleteSessionChoice(index: number) {
    const baseContext = this;
    swal({
        title: "Vous êtes sûr?",
        text: "Cette Session va être supprimée définitivement!",
        type: "warning",
        showCancelButton: true,
        confirmButtonColor: "#EF5350",
        confirmButtonText: "Oui, supprimer!",
        cancelButtonText: "Non, annuler!",
        closeOnConfirm: true,
        closeOnCancel: true
      },
      function (isConfirm) {
        if (isConfirm) {
          baseContext.busy = baseContext.optionalUnitService.deleteSessionChoiceById(baseContext.sessions[index].id_session_choix_ou)
            .subscribe(
              (data) => {
                baseContext.sessions.splice(index, 1);
                swal({
                  title: "Supprimé!",
                  text: "Cet étudiant est supprimé.",
                  confirmButtonColor: "#66BB6A",
                  type: "success"
                });
                Utils.initializeDataTables("sessions-choix-ou-table", 15, 6);
              },
              (error) => {
              }
            );
        } else {
          swal({
            title: "Annulé",
            text: "Vous avez annulé cette action",
            confirmButtonColor: "#2196F3",
            type: "error"
          });
        }
      });
  }

  generateListAffectation(sessionChoiceId: number) {
    let baseContext = this;
    /* generate workbook and add the worksheet */
    const wb: XLSX.WorkBook = XLSX.utils.book_new();

    this.busy = this.optionalUnitService
      .getListAffectationBySession(sessionChoiceId)
      .subscribe(
        (data: StudentChoixOu[]) => {
          var result: any = Utils.groupByM(data, function (item) {
            return [item.id_optional_unit];
          });
          // ['NUM' , 'NOM' , 'PRENOM' , 'CIN/IDENT']
          if (result.length > 0) {
            for (let i = 0; i < result.length; i++) {
              this.contentSheet = [['NUM', 'NOM', 'PRENOM', 'CIN/IDENT']];
              for (let j = 0; j < result[i].length; j++) {
                this.contentSheet.push(
                  [
                    (j + 1),
                    result[i][j].student.last_name,
                    result[i][j].student.first_name,
                    Utils.getIdentifiantStudent(result[i][j].student)]
                )
              }
              let sheet: XLSX.WorkSheet = XLSX.utils.aoa_to_sheet(this.contentSheet);
              XLSX.utils.book_append_sheet(wb, sheet, result[i][0].optional_unit.code);
            }

            XLSX.writeFile(wb, "ResultatsSession-" + sessionChoiceId + ".xlsx");
          }
        },
        (error) => {


        }
      )
  }

  autoAffectation(index: number, sessionChoiceId: number) {
    this.busy = this.optionalUnitService.autoAffectation(sessionChoiceId)
      .subscribe(
        (data) => {
          swal('Succées', "L'affectation est faite automatiquement", "success");
          this.sessions[index].affectation = 1;
        },
        (error) => {
          if (error.status === 403) {
            swal('Erreur', 'Il faut attendre la fermeture du session de choix', 'error');
          }
        })
  }
}
