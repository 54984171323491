import {ItemSessionFormation} from "./item-session-formation";

export class SessionFormation {
  id_session_formation: number;
  label: string;
  year_university: string;
  start_date_choice: string;
  end_date_choice: string;
  affectation: number;

  formations: ItemSessionFormation[] = [];
}
