import {Injectable} from "@angular/core";
import {HttpClient} from "@angular/common/http";
import {catchError} from 'rxjs/operators';
import {GenericService} from "./generic.service";
import {StorageService} from "./storage.service";
import {environment} from "../../../environments/environment";

@Injectable()
export class StudentService extends GenericService {
  constructor(private http: HttpClient, private storageService: StorageService) {
    super();
  }

  getAllStudents() {
    const url = environment.baseUrl + '/admin/student';
    const headers = this.headers.set("Authorization", "Bearer " + this.storageService.read("admin-token"));
    return this.http.get<any>(url, {
      headers: headers
    })
      .pipe(catchError(this.handleErrors));
  }

  getAllUnconfirmedStudents() {
    const url = environment.baseUrl + '/admin/student/nonConfirmed';
    const headers = this.headers.set("Authorization", "Bearer " + this.storageService.read("admin-token"));
    return this.http.get<any>(url, {
      headers: headers
    })
      .pipe(catchError(this.handleErrors));
  }

  getAllStudentsByStatus(requestedStatus: number) {
    const url = environment.baseUrl + '/admin/student/status/' + requestedStatus;
    console.log(url);
    const headers = this.headers.set("Authorization", "Bearer " + this.storageService.read("admin-token"));
    return this.http.get<any>(url, {
      headers: headers
    })
      .pipe(catchError(this.handleErrors));
  }

  updateStudentStatusAfterReview(studentId: number, administrationReview: number) {
    const url = environment.baseUrl + '/admin/student/' + studentId + '/status';
    const headers = this.headers.set("Authorization", "Bearer " + this.storageService.read("admin-token"));
    return this.http.post<any>(url, {
      administration_review: administrationReview
    }, {
      headers: headers
    })
      .pipe(catchError(this.handleErrors));
  }

  deleteStudent(studentId: number) {
    const url = environment.baseUrl + '/admin/student/' + studentId;
    const headers = this.headers.set("Authorization", "Bearer " + this.storageService.read("admin-token"));
    return this.http.delete(url, {
      headers: headers
    })
      .pipe(catchError(this.handleErrors));
  }


  sendConfirmationEmail(studentId: number) {
    const headers = this.headers.set("Authorization", "Bearer " + this.storageService.read("admin-token"));
    const url = environment.baseUrl + "/admin/student/" + studentId + '/sendConfirmation';

    return this.http.get<any>(url,
      {
        headers: headers
      }
    ).pipe(catchError(this.handleErrors));

  }

  sendMail(studentId: number, content: string) {
    const headers = this.headers.set("Authorization", "Bearer " + this.storageService.read("admin-token"));
    const url = environment.baseUrl + "/admin/mail/" + studentId;

    return this.http.post<any>(url, {"content": content},
      {
        headers: headers
      }
    ).pipe(catchError(this.handleErrors));

  }

  getStudentById(studentId: number) {
    const url = environment.baseUrl + '/admin/student/' + studentId;
    const headers = this.headers.set("Authorization", "Bearer " + this.storageService.read("admin-token"));
    return this.http.get<any>(url, {
      headers: headers
    })
      .pipe(catchError(this.handleErrors));

  }

  getStudentDetail(studentId: number) {
    const url = environment.baseUrl + '/admin/student/' + studentId + "/detail";
    const headers = this.headers.set("Authorization", "Bearer " + this.storageService.read("admin-token"));
    return this.http.get<any>(url, {
      headers: headers
    })
      .pipe(catchError(this.handleErrors));
  }

  getAllStudentsByLevel(requestedStatus: number, levelId: number, medecin: number = 1) {
    const url = environment.baseUrl + '/admin/student/status/' + requestedStatus;
    console.log(url);
    const headers = this.headers.set("Authorization", "Bearer " + this.storageService.read("admin-token"));
    return this.http.post<any>(url, {
      levelId: levelId,
      medecin: medecin
    }, {
      headers: headers
    })
      .pipe(catchError(this.handleErrors));
  }

  updateStudentEmail(email: string, studentId: number) {
    const url = environment.baseUrl + '/admin/student/' + studentId + '/updateEmail';
    const headers = this.headers.set("Authorization", "Bearer " + this.storageService.read("admin-token"));
    return this.http.put<any>(url, {
      email: email,
      id_student: studentId
    }, {headers: headers}).pipe(catchError(this.handleErrors));
  }


  getStudentByIdentifant(cin: string, levelId: number) {
    const url = environment.baseUrl + '/admin/student/by-ident';
    const headers = this.headers.set("Authorization", "Bearer " + this.storageService.read("admin-token"));
    return this.http.post<any>(url, {
      'identifiant': cin,
      'levelId': levelId
    }, {
      headers: headers
    })
      .pipe(catchError(this.handleErrors));
  }

  getStudentChoicesUO(cin: string, id_session_choix_ou: number) {
    const url = environment.baseUrl + '/admin/student/choices-uo';
    const headers = this.headers.set("Authorization", "Bearer " + this.storageService.read("admin-token"));
    return this.http.post<any>(url, {
      'identifiant': cin,
      'sessionId': id_session_choix_ou
    }, {
      headers: headers
    })
      .pipe(catchError(this.handleErrors));
  }
}
