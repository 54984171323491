import {Component, OnInit} from '@angular/core';
import {Formation} from "../../shared/models/formation";
import {TypeFormation} from "../../shared/models/type_formation";
import {PostGraduateTrainingService} from "../../shared/services/post-graduate-training.service";
import {FormationNiveau} from "../../shared/models/formation_niveau";
import {TeacherService} from "../../shared/services/teacher.service";
import {Teacher} from "../../shared/models/Teacher";
import {Subscription} from "rxjs/Rx";
import {ActivatedRoute, Router} from "@angular/router";
import {Utils} from "../../shared/utils";


declare var jQuery: any;
declare var swal: any;

@Component({
  selector: 'app-manage-training',
  templateUrl: './manage-training.component.html',
  styleUrls: ['./manage-training.component.css']
})
export class ManageTrainingComponent implements OnInit {

  formation: Formation = new Formation();
  submitted: boolean;
  typesFormation: TypeFormation [] = [];
  formationNiveaux: FormationNiveau[] = [];
  teachers: Teacher[] = [];

  busy: Subscription;

  formationId: number;

  constructor(private postGraduateTrainingService: PostGraduateTrainingService,
              private teacherService: TeacherService,
              private router: Router,
              private route: ActivatedRoute) {
  }

  ngOnInit() {
    const baseContext = this;

    this.formationId = parseInt(this.route.snapshot.paramMap.get('formationId'), 0);
    if (this.formationId) {
      this.loadFormation();
    }

    Utils.initSelect('select-type-formation');
    Utils.initSelect('select-niveaux-formation');
    Utils.initSelect('select-coordinateur-formation');

    this.getAllTypesFormation();
    this.getAllFormationNiveaux();
    this.getAllTeachers();
    this.initRadioBox();
  }

  loadFormation() {
    this.busy = this.postGraduateTrainingService.getFormationById(this.formationId)
      .subscribe(
        (data: Formation) => {
          this.formation = data;
          Utils.setValuesjQueryCmp('select-type-formation', this.formation.id_type_formation, 20);
          Utils.setValueToRadioBox('memoire-radio-box', this.formation.isMemoire, 20);
          Utils.setValueToRadioBox('duration-radio-box', this.formation.duration, 20);
        },
        (error) => {

        }
      )
  }


  getAllTypesFormation() {
    const baseContext = this;
    this.busy = this.postGraduateTrainingService.getAllTypesFormation()
      .subscribe(
        (data) => {
          this.typesFormation = data;
          Utils.setValuesjQueryCmp('select-type-formation', this.formation.id_type_formation, 20);
        },
        (error) => {

        }
      )
  }

  getAllFormationNiveaux() {
    const baseContext = this;
    this.busy = this.postGraduateTrainingService.getAllFormationNiveaux()
      .subscribe(
        (data) => {
          this.formationNiveaux = data;

          this.formation.niveauIds = this.formation.niveaux.map(niveau => {
            return niveau.id_formation_niveau
          });
          Utils.setValuesjQueryCmp('select-niveaux-formation', this.formation.niveauIds, 20);
        },
        (error) => {

        }
      )
  }

  getAllTeachers() {
    const baseContext = this;
    this.busy = this.teacherService.getAllTeachersByStatus(1)
      .subscribe(
        (data) => {
          this.teachers = data;
          Utils.setValuesjQueryCmp('select-coordinateur-formation', this.formation.id_coordinateur, 20);
        },
        (error) => {

        }
      )
  }


  isValidChamps() {
    console.log(this.formation);
    if (!this.formation.label || !this.formation.description || !this.formation.id_type_formation
      || !this.formation.id_coordinateur || this.formation.isMemoire === undefined || !this.formation.duration || this.formation.niveauIds.length == 0) {
      return false;
    }
    return true;
  }


  submitCreateTraining() {
    this.submitted = true;
    this.formation.id_type_formation = parseInt(jQuery('.select-type-formation').val(), 0);
    this.formation.niveauIds = jQuery('.select-niveaux-formation').val();
    this.formation.id_coordinateur = parseInt(jQuery('.select-coordinateur-formation').val(), 0);


    console.log(this.formation);

    if (!this.isValidChamps()) {
      swal('Warning', 'Verifier vos champs', 'warning');
      return;
    }
    if (!this.formationId) {
      this.busy = this.postGraduateTrainingService.addFormation(this.formation)
        .subscribe(
          (data) => {
            swal('Succées', 'Formation ajoutée avec succées', 'success');
            this.router.navigate(['/post-graduated-training/list']);
          },
          (error) => {
            swal('Erreur', "Erreur d'ajout de formation", 'error');
          }
        );
    } else {
      this.busy = this.postGraduateTrainingService.editFormation(this.formation)
        .subscribe(
          (data) => {
            swal('Succées', 'Formation modifié avec succées', 'success');
            this.router.navigate(['/post-graduated-training/list']);
          },
          (error) => {
            swal('Erreur', "Erreur de modification de formation", 'error');
          }
        )
    }
    console.log(this.formation);
  }

  private initRadioBox() {
    const baseContext = this;
    jQuery(".radioBox").uniform({
      radioClass: 'choice'
    });

    jQuery('.duration-radio-box').on('change', function () {
      baseContext.formation.duration = parseInt(jQuery(this).val(), 0);
    });
    jQuery('.memoire-radio-box').on('change', function () {
      baseContext.formation.isMemoire = jQuery(this).val();
    });
  }
}
