import {Component, OnInit} from '@angular/core';
import {Formation} from "../../shared/models/formation";
import {PreInscriptionFormation} from "../../shared/models/pre_inscription_formation";
import {Subscription} from "rxjs/Rx";
import {Teacher} from "../../shared/models/Teacher";
import {Student} from "../../shared/models/student";
import {ActivatedRoute} from "@angular/router";
import {PostGraduateTrainingService} from "../../shared/services/post-graduate-training.service";
import * as moment from "moment";
import {environment} from "../../../environments/environment";

declare var swal: any;
declare var jQuery: any;

@Component({
  selector: 'app-list-demands-per-training',
  templateUrl: './list-demands-per-training.component.html',
  styleUrls: ['./list-demands-per-training.component.css']
})

export class ListDemandsPerTrainingComponent implements OnInit {

  formationId: number;
  sessionId: number;

  formation: Formation = new Formation();
  preInscriptionsFormation: PreInscriptionFormation[] = [];

  busy: Subscription;

  baseUrl: string = environment.baseUrl + "/";

  selectedTeacher: Teacher;
  selectedStudent: Student;

  constructor(private route: ActivatedRoute, private postGraduateTrainingService: PostGraduateTrainingService) {
    this.formationId = parseInt(this.route.snapshot.paramMap.get('formationId'), 0);
    this.sessionId = parseInt(this.route.snapshot.paramMap.get('sessionId'), 0);
  }

  ngOnInit() {

    this.loadFormation();
    this.loadCandidatsByFormation();

  }

  loadCandidatsByFormation() {
    this.busy = this.postGraduateTrainingService.getPreInscriptionsByFormation(this.sessionId, this.formationId)
      .subscribe(
        (data: PreInscriptionFormation[]) => {
          this.preInscriptionsFormation = data;
          this.initializeDataTables(20, 9);

        },
        (error) => {
          if (error.status === 401) {
            swal("Erreur", "Vous n'avez pas droit d'accéeder à cette formation", "error");
          }
        }
      )
  }

  loadFormation() {
    this.busy = this.postGraduateTrainingService.getFormationById(this.formationId)
      .subscribe(
        (data: Formation) => {
          this.formation = data;
        },
        (error) => {
          if (error.status === 401) {
            swal("Erreur", "Vous n'avez pas droit d'accéeder à cette formation", "error");
          }

        }
      )
  }

  viewDetailCandidate(index: number) {
    this.selectedStudent = null;
    this.selectedTeacher = null;

    if (this.preInscriptionsFormation[index].teacher) {
      this.selectedTeacher = this.preInscriptionsFormation[index].teacher;
      this.selectedTeacher.age = moment().diff(this.selectedTeacher.birthday, 'years');
    }

    if (this.preInscriptionsFormation[index].student) {
      this.selectedStudent = this.preInscriptionsFormation[index].student;
      this.selectedStudent.age = moment().diff(this.selectedStudent.birthday, 'years');
    }

    setTimeout(function () {
      jQuery('.modal-detail-candidate').modal();
    }, 20);
  }

  initializeDataTables(timout: number, columnNumber: number, orderBy ?: number) {
    // Basic datatable
    const tableListStation = jQuery('.datatable-basic');
    if (jQuery.fn.DataTable.isDataTable('.datatable-basic')) {
      tableListStation.dataTable().fnDestroy();
    }
    setTimeout(function () {
      tableListStation.DataTable({
        "bSort": false,
        columnDefs: [{
          targets: [columnNumber - 1]
        }],
        buttons: {
          dom: {
            button: {
              className: 'btn btn-default'
            }
          },
          buttons: [
            'excelHtml5',
            'pdfHtml5'
          ]
        }
      });
    }, timout);
  }


  toggleInscription(id_formation, idx) {
    this.busy = this.postGraduateTrainingService.toggleInscription(id_formation)
      .subscribe(
        (data: PreInscriptionFormation) => {
          this.preInscriptionsFormation[idx] = data;
        },
        (error) => {
          if (error.status === 401) {
            swal("Erreur", "Vous n'avez pas droit d'accéeder à cette formation", "error");
          }

        }
      )
  }
}
