import {NgModule} from "@angular/core";
import {RouterModule, Routes} from "@angular/router";

import {BoiteReclamationComponent} from "./boite-reclamation/boite-reclamation.component";
import {ReclamationVayetekComponent} from "./reclamation-vayetek/reclamation-vayetek.component";
import {MessagesModule} from "./boite-messages/messages.module";

export function loadMessagesModule() {
  return MessagesModule
}

export const routes: Routes = [
  {
    path: 'boitereclamation',
    component: BoiteReclamationComponent
  },
  {
    path: 'reclamationvayetek',
    component: ReclamationVayetekComponent
  },
  {
    path: 'messages',
    loadChildren: loadMessagesModule
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class SupportModuleRouting {
}
