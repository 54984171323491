import {Component, OnInit} from '@angular/core';
import {Subscription} from "rxjs/Rx";
import {PostGraduateTrainingService} from "../../shared/services/post-graduate-training.service";
import {PreInscriptionFormation} from "../../shared/models/pre_inscription_formation";
import {Teacher} from "../../shared/models/Teacher";
import {SessionFormation} from "../../shared/models/session_formation";
import {Utils} from "../../shared/utils";
import {ActivatedRoute, Router} from "@angular/router";


declare var swal: any;
declare var jQuery: any;

@Component({
  selector: 'app-list-demands-training',
  templateUrl: './list-demands-training.component.html',
  styleUrls: ['./list-demands-training.component.css']
})
export class ListDemandsTrainingComponent implements OnInit {

  busy: Subscription;
  preInscriptionsFormation: PreInscriptionFormation[] = [];

  sessionId: number;

  constructor(private postGratuatedService: PostGraduateTrainingService,
              private router: Router,
              private route: ActivatedRoute) {
    this.sessionId = parseInt(this.route.snapshot.paramMap.get("sessionId"), 0);
  }

  ngOnInit() {
    this.getAllDemandsBySession();
  }

  private getAllDemandsBySession() {

    this.busy = this.postGratuatedService.getAllDemandsTraining(this.sessionId)
      .subscribe(
        (data: PreInscriptionFormation[]) => {
          this.preInscriptionsFormation = data;
          setTimeout(function () {

            jQuery('.datatable-basic').DataTable({
              autoWidth: false,
              columns: [
                {width: '15%'},
                {width: '20%'},
                {width: '15%'},
                {width: '20%'},
                {width: '20%'},
                {width: '20%'},
                {width: '20%'}
              ],
              columnDefs: [{
                targets: [6]
              }],
              order: [[0, "desc"]]

            })
          }, 20);
        },
        (error) => {
        }
      )
  }

}
