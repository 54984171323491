import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {ListOptionalUnitComponent} from './list-optional-unit/list-optional-unit.component';
import {EditOptionalUnitComponent} from './edit-optional-unit/edit-optional-unit.component';
import {SharedModule} from "../shared/shared.module";
import {OptionalUnitRoutingModule} from "./optional-unit.routing";
import {ListSessionOuComponent} from './list-session-ou/list-session-ou.component';
import {EditSessionOuComponent} from './edit-session-ou/edit-session-ou.component';
import {ManuelAffecationOUComponent} from './manuel-affecation-ou/manuel-affecation-ou.component';

@NgModule({
  imports: [
    CommonModule,
    SharedModule,
    OptionalUnitRoutingModule
  ],
  declarations: [
    ListOptionalUnitComponent,
    EditOptionalUnitComponent,
    ListSessionOuComponent,
    EditSessionOuComponent,
    ManuelAffecationOUComponent],
  providers: []
})
export class OptionalUnitModule {
}
