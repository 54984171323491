import {Component, OnInit} from '@angular/core';
import {SessionChoixOU} from "../../shared/models/optional-unit/session-choix-ou";
import {Subscription} from "rxjs";
import {Level} from "../../shared/models/level";
import {SharedService} from "../../shared/services/shared.service";
import {Utils} from "../../shared/utils";
import {OptionalUnit} from "../../shared/models/optional-unit/optional-unit";
import {OptionalUnitService} from "../../shared/services/optional-unit.service";
import {SessionOU} from "../../shared/models/optional-unit/session-ou";
import {ActivatedRoute, Router} from "@angular/router";
import {GroupOU} from "../../shared/models/optional-unit/GroupOU";
import {Domaine} from "../../shared/models/optional-unit/domaine";

declare var swal: any;

@Component({
  selector: 'app-edit-session-ou',
  templateUrl: './edit-session-ou.component.html',
  styleUrls: ['./edit-session-ou.component.css']
})
export class EditSessionOuComponent implements OnInit {

  sessionChoice: SessionChoixOU = new SessionChoixOU();
  busy: Subscription;

  submitted: boolean;

  levels: Level[] = [];

  years: string[] = [];

  domaines: Domaine[] = [];

  optionalUnits: OptionalUnit[] = [];

  constructor(private sharedServices: SharedService,
              private optionalUnitService: OptionalUnitService,
              private sharedService: SharedService,
              private route: ActivatedRoute,
              private router: Router) {
  }

  ngOnInit() {
    const baseContext = this;
    this.years = Utils.getUniversityYears(1990);
    this.loadLevels();
    this.loadDomaines();
    this.loadOptionalUnits();

    this.route.params.subscribe(
      params => {
        const sessionChoiceId = +params["sessionChoiceId"];
        if (sessionChoiceId) {
          this.optionalUnitService.getSessionOptionalUnitById(sessionChoiceId)
            .subscribe(
              (data: SessionChoixOU) => {
                baseContext.sessionChoice = data;
                baseContext.sessionChoice.groupes_ou.forEach(
                  item => {
                    item.domaineIds = item.domaines.map(
                      domaine => {
                        return domaine.id_domaine;
                      }
                    )
                  }
                );
                console.log(baseContext.sessionChoice);
                baseContext.settingTitles();
              });
        }
      });

  }


  loadOptionalUnits() {
    this.busy = this.optionalUnitService
      .getAllOptionalUnits()
      .subscribe(
        (data) => {
          this.optionalUnits = data;
        },
        (error) => {
        }
      )
  }

  loadLevels() {
    this.busy = this.sharedServices.getAllLevels()
      .subscribe(
        (data) => {
          this.levels = data;
        },
        (error) => {
        }
      )
  }


  onCodeUnitChange(index: number) {
    console.log(this.sessionChoice.sessions_ou[index]);

    console.log(this.optionalUnits);
    const baseContext = this;
    const indexOptionalUnit = this.optionalUnits.map(
      optionalUnit => {
        return optionalUnit.id_optional_unit
      }
    ).indexOf(parseInt(baseContext.sessionChoice.sessions_ou[index].id_optional_unit));
    console.log(indexOptionalUnit);
    this.sessionChoice.sessions_ou[index].titleOptionalUnit = this.optionalUnits[indexOptionalUnit].title;
    this.sessionChoice.sessions_ou[index].domaine = this.optionalUnits[indexOptionalUnit].domaine.label;
  }

  submitCreateSessionChoice() {
    this.submitted = true;

    if (!this.isChampsOUValid() || !this.isChampsBasicValid()) {
      return;
    }

    if (!this.sessionChoice.id_session_choix_ou) {
      this.busy = this.optionalUnitService
        .addSessionChoice(this.sessionChoice)
        .subscribe(
          (data) => {
            swal('Succées', 'Session de choix ajoutée avec succées', 'success');
            this.router.navigate(['/optional-unit/sessions']);
          },
          (error) => {
            swal('Erreur', "Erreur d'ajout de cette session de choix", 'error');
          }
        );
    } else {
      this.busy = this.optionalUnitService
        .editSessionChoice(this.sessionChoice)
        .subscribe(
          (data) => {
            swal('Succées', 'Session de choix modifiée avec succées', 'success');
            this.router.navigate(['/optional-unit/sessions']);
          },
          (error) => {
            swal('Erreur', "Erreur de modification de cette session de choix", 'error');
          }
        );
    }

    console.log(this.sessionChoice);
  }

  removeOU() {
    this.sessionChoice.sessions_ou.pop();
  }

  addOU() {

    if (!this.isChampsOUValid()) {
      return;
    }
    this.sessionChoice.sessions_ou.push(new SessionOU());
  }

  settingTitles() {
    for (let i = 0; i < this.sessionChoice.sessions_ou.length; i++) {
      this.sessionChoice.sessions_ou[i].titleOptionalUnit = this.sessionChoice.sessions_ou[i].optional_unit.title;
    }
  }

  private isChampsOUValid() {
    for (let i = 0; i < this.sessionChoice.sessions_ou.length; i++) {
      if (!this.isElementOUValid(this.sessionChoice.sessions_ou[i])) {
        return false;
      }
    }
    return true;
  }

  private isElementOUValid(sessionOU: SessionOU) {
    return sessionOU.nb_places && sessionOU.titleOptionalUnit && sessionOU.id_optional_unit;
  }

  private isChampsBasicValid() {
    return this.sessionChoice.title && this.sessionChoice.id_level && this.sessionChoice.start_date && this.sessionChoice.end_date && this.sessionChoice.year_university;
  }

  addGroupe() {

    if (!this.isChampsGroupeOUValid()) {
      return;
    }
    this.sessionChoice.groupes_ou.push(new GroupOU());
  }

  private loadDomaines() {
    this.sharedService.getAllDomaines()
      .subscribe(
        (data) => {
          this.domaines = data;
        },
        (error) => {

        }
      )
  }

  removeGroupe() {
    this.sessionChoice.groupes_ou.pop();
  }

  private isChampsGroupeOUValid() {

    for (let i = 0; i < this.sessionChoice.groupes_ou.length; i++) {
      if (!this.isElementGroupeOUValid(this.sessionChoice.groupes_ou[i])) {
        return false;
      }
    }
    return true;
  }

  private isElementGroupeOUValid(groupOUS: GroupOU) {
    return groupOUS.label && groupOUS.domaineIds.length !== 0;
  }
}
