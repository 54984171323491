import {NgModule} from "@angular/core";
import {CommonModule} from "@angular/common";
import {FormsModule} from "@angular/forms";
import {NgBusyModule} from "ng-busy";
import {StorageService} from "./services/storage.service";
import {MomentModule} from "ngx-moment";
import {NgSelect2Component} from './components/ng-select2/ng-select2.component';
import {NgDatepickerComponent} from './components/ng-datepicker/ng-datepicker.component';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    NgBusyModule,
    MomentModule],
  exports: [
    CommonModule,
    FormsModule,
    NgBusyModule,
    MomentModule,
    NgDatepickerComponent,
    NgSelect2Component],
  declarations: [NgSelect2Component, NgDatepickerComponent],
  providers: [StorageService]
})
export class SharedModule {

}
