import { Component, OnInit } from '@angular/core';
import {Student} from "../../shared/models/student";
import {Utils} from "../../shared/utils";
import {Subscription} from "rxjs/Subscription";
import {StudentService} from "../../shared/services/student.service";
import {logging} from "selenium-webdriver";
declare let jQuery: any;
declare let swal: any;
@Component({
  selector: 'app-list-nonconfirmed',
  templateUrl: './list-nonconfirmed.component.html',
  styleUrls: ['./list-nonconfirmed.component.css']
})
export class ListNonconfirmedComponent implements OnInit {
  students : Student[] =[];
  busy: Subscription;
  private selectedStudent: Student =new Student();
  private initialEmail: string;

  constructor(private studentService: StudentService) { }

  ngOnInit() {
    this.getNonConfirmedStudents();
  }

  private getNonConfirmedStudents() {
    this.busy = this.studentService.getAllUnconfirmedStudents().subscribe(data => {
      this.students = data;
      console.log(this.students);
       Utils.initializeDataTables('datatable-basic',200, 5);

    });
  }

  openModalEmail(index: number) {
    this.initialEmail = this.students [index].email;
    this.selectedStudent = this.students[index];
    jQuery("#modal_form_vertical").modal();

  }
  sendMailConfirmation(i) {
    this.selectedStudent = this.students[i];

    this.busy =   this.studentService.sendConfirmationEmail(this.selectedStudent.id_student)
      .subscribe(data => {
        swal('Success', 'Email de confirmation envoyé', 'success');

        Utils.initializeDataTables('datatable-basic',200, 5);
      },(error) => {
        swal('Error', 'Erreur, Email non envoyé', 'error');

      });

  }

  changeEMail() {
    this.busy =   this.studentService.updateStudentEmail(this.selectedStudent.email,this.selectedStudent.id_student)
      .subscribe(data => {
        swal('Success', 'Email mis à jour avec succèes', 'success');

        Utils.initializeDataTables('datatable-basic',200, 5);
    },(error) => {
        this.selectedStudent.email=this.initialEmail;

        swal('Error', 'Erreur, Contactez un administrateur.', 'error');

      });
    jQuery("#modal_form_vertical").modal('toggle');

  }

  deleteStudent(index: number) {
    const baseContext = this;
    const student: Student = this.students[index];
    swal({
        title: "Vous êtes sûr?",
        text: "Cet étudiant va être supprimer définitivement!",
        type: "warning",
        showCancelButton: true,
        confirmButtonColor: "#EF5350",
        confirmButtonText: "Oui, supprimer!",
        cancelButtonText: "Non, annuler!",
        closeOnConfirm: true,
        closeOnCancel: true
      },
      function (isConfirm) {
        if (isConfirm) {
          baseContext.busy = baseContext.studentService.deleteStudent(student.id_student).subscribe(data => {
            baseContext.students.splice(index, 1);
            swal({
              title: "Supprimé!",
              text: "Cet étudiant est supprimé.",
              confirmButtonColor: "#66BB6A",
              type: "success"
            });
          }, error => {

          });
        } else {
          swal({
            title: "Annulé",
            text: "Vous avez annulé cette action",
            confirmButtonColor: "#2196F3",
            type: "error"
          });
        }
      });
  }

}
