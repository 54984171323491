import {Component, OnInit} from '@angular/core';
import {OptionalUnitService} from "../../shared/services/optional-unit.service";
import {OptionalUnit} from "../../shared/models/optional-unit/optional-unit";
import {Level} from "../../shared/models/level";
import {Teacher} from "../../shared/models/Teacher";
import {StudentFileService} from "../../shared/services/student-file.service";
import {Subscription} from "rxjs/Subscription";
import {TeacherService} from "../../shared/services/teacher.service";
import {Utils} from "../../shared/utils";

declare let jQuery;
declare let swal;

@Component({
  selector: 'app-list-optional-unit',
  templateUrl: './list-optional-unit.component.html',
  styleUrls: ['./list-optional-unit.component.css']
})
export class ListOptionalUnitComponent implements OnInit {

  optionalUnits: Array<OptionalUnit> = [];
  levels: Level[] = [];
  coordinateurs: Teacher[] = [];

  busy: Subscription;
  selectedLevel: number;

  constructor(private optionalUnitService: OptionalUnitService,
              private teacherService: TeacherService,
              private studentFileService: StudentFileService) {
  }

  ngOnInit() {
    this.getAllOptionalUnits();
    this.getAllLevels();
    this.getAllTeachers()
  }

  refreshOptionalUnitsListByLevelId(levelId: number) {
    this.busy = this.optionalUnitService.getOptionalUnitByLevel(levelId).subscribe(data => {
      this.optionalUnits = data;
      Utils.initializeDataTables('datatable-basic', 50, 5);
    })
  }

  getAllOptionalUnits() {
    this.busy = this.optionalUnitService.getAllOptionalUnits().subscribe(data => {
      this.optionalUnits = data;
      Utils.initializeDataTables('datatable-basic', 50, 5);
    });
  }

  getAllLevels() {
    const baseContext = this;
    this.studentFileService.getAllLevels()
      .subscribe(
        (data) => {
          this.levels = data;
          setTimeout(function () {
            baseContext.initSelectLevel();
          }, 20);
        }
      )
  }

  getAllTeachers() {
    const baseContext = this;
    this.teacherService.getAllTeachersByStatus(1)
      .subscribe(
        (data) => {
          this.coordinateurs = data;
          setTimeout(function () {
            baseContext.initSelectTeachers();
          }, 20);
        }
      )
  }

  initSelectTeachers() {
    const selectCoordinateur = jQuery('.select-coordinateur');
    selectCoordinateur.select2();
  }

  initSelectLevel() {
    const baseContext = this;
    const selectLevel = jQuery('.select-level');
    selectLevel.select2();
    selectLevel.on("change", function () {
      baseContext.selectedLevel = +jQuery(this).val();
      if (baseContext.selectedLevel > 0) {
        baseContext.refreshOptionalUnitsListByLevelId(baseContext.selectedLevel)
      } else if (baseContext.selectedLevel === 0) {
        baseContext.getAllOptionalUnits()
      }
    });
  }

  deleteOptionalUnit(index) {
    const baseContext = this;
    swal({
        title: "Vous êtes sûr?",
        text: "Cette unité optionnelle va être supprimée définitivement!",
        type: "warning",
        showCancelButton: true,
        confirmButtonColor: "#EF5350",
        confirmButtonText: "Oui, supprimer!",
        cancelButtonText: "Non, annuler!",
        closeOnConfirm: true,
        closeOnCancel: true
      },
      function (isConfirm) {
        if (isConfirm) {
          baseContext.busy = baseContext.optionalUnitService.deleteOptionalUnit(baseContext.optionalUnits[index])
            .subscribe(data => {
              baseContext.optionalUnits.splice(index, 1);
              swal({
                title: "Supprimé!",
                text: "Cet étudiant est supprimé.",
                confirmButtonColor: "#66BB6A",
                type: "success"
              });
              Utils.initializeDataTables('datatable-basic', 50, 5);
            }, error => {

            });
        } else {
          swal({
            title: "Annulé",
            text: "Vous avez annulé cette action",
            confirmButtonColor: "#2196F3",
            type: "error"
          });
        }
      });
  }
}
