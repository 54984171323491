import {Component, OnInit} from '@angular/core';
import {Subscription} from "rxjs";
import {Seminar} from "../../shared/models/mseminar/Seminar";
import {MseminarService} from "../../shared/services/mseminar.service";
import {Utils} from "../../shared/utils";

declare var swal: any;

@Component({
  selector: 'app-list-seminar',
  templateUrl: './list-seminar.component.html',
  styleUrls: ['./list-seminar.component.css']
})
export class ListSeminarComponent implements OnInit {

  busy: Subscription;

  seminars: Seminar[] = [];

  constructor(private seminarServices: MseminarService) {
  }

  ngOnInit() {
    this.getAllSeminars();
  }

  private getAllSeminars() {
    this.busy = this.seminarServices.getAllSeminars()
      .subscribe(
        (data: Seminar[]) => {
          this.seminars = data;
          Utils.initializeDataTables("list-seminar", 15);
        },
        (error) => {
        }
      )
  }

  deleteSeminar(index: number) {
    const baseContext = this;
    swal({
        title: "Vous êtes sûr?",
        text: "Ce Mini Séminaire à été supprimée définitivement!",
        type: "warning",
        showCancelButton: true,
        confirmButtonColor: "#EF5350",
        confirmButtonText: "Oui, supprimer!",
        cancelButtonText: "Non, annuler!",
        closeOnConfirm: true,
        closeOnCancel: true
      },
      function (isConfirm) {
        if (isConfirm) {
          baseContext.busy = baseContext.seminarServices.deleteSeminar(baseContext.seminars[index].id_seminar)
            .subscribe(
              (data) => {
                baseContext.seminars.splice(index, 1);
                swal("Succées", "Supression séminaire avec succées", "success");
                Utils.initializeDataTables("list-seminar", 15);
              },
              (error) => {

              }
            );
        } else {
          swal({
            title: "Annulé",
            text: "Vous avez annulé cette action",
            confirmButtonColor: "#2196F3",
            type: "error"
          });
        }
      });
  }
}
