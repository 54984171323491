import {Component, OnInit} from '@angular/core';
import {Subscription} from "rxjs/index";
import {ConversationService} from "../../../shared/services/conversation.service";
import {Conversation} from "../../../shared/models/conversation";
import {ActivatedRoute} from "@angular/router";
import {environment} from "../../../../environments/environment";

@Component({
  selector: 'app-all-messages-unregistered',
  templateUrl: './all-messages-unregistered.component.html',
  styleUrls: ['./all-messages-unregistered.component.css']
})
export class AllMessagesUnregisteredComponent implements OnInit {

  busy: Subscription;
  conversations: Array<Conversation>;
  baseUrl = environment.baseUrl + '/';

  constructor(private conversationServices: ConversationService, private route: ActivatedRoute) {

  }

  ngOnInit() {
    this.getAllMessages();
  }

  private getAllMessages() {
    this.route.params.subscribe(params => {
      const user = params["user"];
      this.busy = this.conversationServices.getAllConversationsUnregistered(user).subscribe(data => {
        this.conversations = data;
      })
    });
  }

}
