import {Component, OnInit} from '@angular/core';
import {SessionFormation} from "../../shared/models/session_formation";
import {PostGraduateTrainingService} from "../../shared/services/post-graduate-training.service";
import {Subscription} from "rxjs";
import {Utils} from "../../shared/utils";
import {Teacher} from "../../shared/models/Teacher";
import {UserService} from "../../shared/services/user.service";

declare var swal: any;
declare var jQuery: any;

@Component({
  selector: 'app-manage-session-post',
  templateUrl: './manage-session-post.component.html',
  styleUrls: ['./manage-session-post.component.css']
})
export class ManageSessionPostComponent implements OnInit {

  sessions: SessionFormation[] = [];

  selectedSessionId: number;

  coordinateurs: Teacher[] = [];
  busy: Subscription;

  isAdminRole: boolean;

  constructor(private postGraduateTrainingServices: PostGraduateTrainingService,
              private userService: UserService) {

    this.isAdminRole = this.userService.checkIfAdminHasRole(1);
  }

  ngOnInit() {

    this.getAllSessions();

  }

  private getAllSessions() {
    this.busy = this.postGraduateTrainingServices.getAllSessions()
      .subscribe(
        (data: SessionFormation[]) => {
          this.sessions = data;

          Utils.initializeDataTables("sessions-master-table", 15, 6);
        }
      )
  }

  deleteSession(index) {
    const baseContext = this;
    swal({
        title: "Vous êtes sûr?",
        text: "Cette Session va être supprimée définitivement!",
        type: "warning",
        showCancelButton: true,
        confirmButtonColor: "#EF5350",
        confirmButtonText: "Oui, supprimer!",
        cancelButtonText: "Non, annuler!",
        closeOnConfirm: true,
        closeOnCancel: true
      },
      function (isConfirm) {
        if (isConfirm) {
          baseContext.busy = baseContext.postGraduateTrainingServices.deleteSessionFormationById(baseContext.sessions[index].id_session_formation)
            .subscribe(
              (data) => {
                baseContext.sessions.splice(index, 1);
                swal({
                  title: "Supprimé!",
                  text: "Cet étudiant est supprimé.",
                  confirmButtonColor: "#66BB6A",
                  type: "success"
                });
                Utils.initializeDataTables("sessions-master-table", 15, 6);
              },
              (error) => {
              }
            );
        } else {
          swal({
            title: "Annulé",
            text: "Vous avez annulé cette action",
            confirmButtonColor: "#2196F3",
            type: "error"
          });
        }
      });
  }

  verifyValidations(index: number, sessionId: number) {
    const baseContext = this;
    swal({
        title: "Vous êtes sûr?",
        text: "Ceci implique l'affectation de tous les candidats dans les différents formations",
        type: "warning",
        showCancelButton: true,
        confirmButtonColor: "#EF5350",
        confirmButtonText: "Oui",
        cancelButtonText: "Non, annuler!",
        closeOnConfirm: true,
        closeOnCancel: true
      },
      function (isConfirm) {
        if (isConfirm) {
          baseContext.busy = baseContext.postGraduateTrainingServices.verifyValidations(sessionId)
            .subscribe(
              (data) => {
                baseContext.affectCandidates(sessionId);
              },
              (error) => {
                if (error.status === 400) {
                  console.log(error);
                  jQuery('.modal-coordiateur-trait-cand').modal();
                  baseContext.coordinateurs = error.error.data;
                  baseContext.selectedSessionId = sessionId;
                }
              }
            )
        } else {
        }
      });
  }

  affectCandidates(sessionId) {
    jQuery('.modal-coordiateur-trait-cand').modal('hide');
    this.busy = this.postGraduateTrainingServices.affectCandidatesFormations(sessionId)
      .subscribe(
        (data) => {
          const index = this.sessions.map(session => {
            return session.id_session_formation
          })
            .indexOf(sessionId);
          this.sessions[index].affectation = 1;
          swal('Succées', "L'affectation avec succées consulter liste des inscrits à chaque formation", "success");
        },
        (error) => {
          swal("Erreur", "Affectation non faite", "error");
        }
      )

  }

  notifMail() {
    jQuery('.modal-coordiateur-trait-cand').modal('hide');
    this.busy = this.postGraduateTrainingServices.sendMailToCoordinateurs(this.selectedSessionId, this.coordinateurs)
      .subscribe(
        (data) => {
          swal('Succée', 'Un mail à été envoyé aux coordinateurs', 'success');
        },
        (error) => {

        }
      )
  }
}
