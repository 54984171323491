import {OptionalUnit} from "./optional-unit";
import {StudentChoixOu} from "./student-choix-ou";

export class SessionOU {
  id_session_ou: number;

  id_optional_unit: string;
  titleOptionalUnit: string;

  domaine: string;

  nb_places: number;

  optional_unit: OptionalUnit = new OptionalUnit();

  inscriptionsNumbers: InscriptionOU[] = [];
  students_choice: StudentChoixOu[] = [];
}

export class InscriptionOU {
  inscriptionsNumbers: number;
  isInscrit: boolean;
}
