import {Component, OnInit} from '@angular/core';
import {SeminarSession} from "../../shared/models/mseminar/SeminarSession";
import {Level} from "../../shared/models/level";
import {SharedService} from "../../shared/services/shared.service";
import {Subscription} from "rxjs";
import {Utils} from "../../shared/utils";
import {Seminar} from "../../shared/models/mseminar/Seminar";
import {MseminarService} from "../../shared/services/mseminar.service";
import {SeanceSeminar} from "../../shared/models/mseminar/SeanceSeminar";
import {ActivatedRoute, Router} from "@angular/router";
import {EventObject} from "../../shared/models/event-object";

declare var jQuery: any;
declare var swal: any;

@Component({
  selector: 'app-form-session-seminar',
  templateUrl: './form-session-seminar.component.html',
  styleUrls: ['./form-session-seminar.component.css']
})
export class FormSessionSeminarComponent implements OnInit {

  session: SeminarSession;

  submitted: boolean;
  levels: Level[] = [];
  busy: Subscription;

  hours: string[] = [];
  years: string[] = [];

  seminars: Seminar[] = [];

  selectedSeance: SeanceSeminar = new SeanceSeminar();

  sessionId: number;

  constructor(private sharedService: SharedService,
              private mseminarService: MseminarService,
              private router: Router,
              private route: ActivatedRoute) {
    this.session = new SeminarSession();
    this.sessionId = parseInt(this.route.snapshot.paramMap.get('sessionId'), 0);
  }

  ngOnInit() {
    const baseContext = this;
    this.getAllLevels();
    this.getAllSeminars();
    this.years = Utils.getUniversityYears(2018);
    this.hours = Utils.getHours();
    jQuery('.seances-list').fullCalendar({
      locale: 'fr',
      header: {
        left: 'prev,next today',
        center: 'title'
      },
      defaultView: 'month',
      editable: true,
      events: [],
      dayClick: function (date, jsEvent, view) {
        baseContext.selectedSeance = new SeanceSeminar();
        baseContext.selectedSeance.date = Utils.convertDateToString(date._d);
        jQuery('#modal_seance_seminar').modal('show');
      },
      eventRender: function (event, element) {
        element.append("<span class='removebtn'>X</span>");
        element.find(".removebtn").click(function () {
          const indexSeance = baseContext.session.seances.map(
            item => {
              return item.ui_id
            }
          ).indexOf(event._id);
          baseContext.session.seances.splice(indexSeance, 1);
          jQuery('.seances-list').fullCalendar('removeEvents', event._id);
        });
      },
      eventClick: function (calEvent, jsEvent, view) {
        const indexSeance = baseContext.session.seances.map(
          item => {
            return item.ui_id
          }
        ).indexOf(calEvent._id);
        console.log(calEvent);
        baseContext.selectedSeance = baseContext.session.seances[indexSeance];
        baseContext.selectedSeance.start_time = Utils.getTime(baseContext.selectedSeance.start_date);
        baseContext.selectedSeance.end_time = Utils.getTime(baseContext.selectedSeance.end_date);
        console.log(baseContext.selectedSeance);
        console.log(baseContext.session.seances);
        setTimeout(function () {
          jQuery('#modal_seance_seminar').modal('show');
        }, 15);
      }
    });

    if (this.sessionId) {
      this.getSeminarSessionById(this.sessionId);
    }
  }

  getAllLevels() {
    this.busy = this.sharedService.getAllLevels()
      .subscribe(
        (data) => {
          this.levels = data;
        }
      )
  }

  getSeminarSessionById(seminarSessionId: number) {
    this.busy = this.mseminarService.getSeminarSessionById(seminarSessionId)
      .subscribe(
        (data: SeminarSession) => {
          this.session = data;
          for (let i = 0; i < this.session.seances.length; i++) {
            this.session.seances[i].ui_id = Utils.getRandomString();
            jQuery('.seances-list').fullCalendar('renderEvent', {
              id: this.session.seances[i].ui_id,
              start: Utils.convertDateStringToDate(this.session.seances[i].start_date),
              end: Utils.convertDateStringToDate(this.session.seances[i].end_date),
              title: this.session.seances[i].seminar.title,
            }, true);
          }
        },
        (error) => {

        }
      )
  }


  addSeance() {

    if (this.selectedSeance.start_time && this.selectedSeance.end_time && this.selectedSeance.id_seminar) {
      const baseContext = this;
      const uiId = Utils.getRandomString();
      this.selectedSeance.ui_id = uiId;
      this.selectedSeance.start_date = this.selectedSeance.date + " " + this.selectedSeance.start_time;
      this.selectedSeance.end_date = this.selectedSeance.date + " " + this.selectedSeance.end_time;
      const indexSeminar = this.seminars.map(item => {
          return item.id_seminar
        }
      ).indexOf(parseInt(this.selectedSeance.id_seminar, 0));
      jQuery('.seances-list').fullCalendar('renderEvent', {
        id: uiId,
        title: baseContext.seminars[indexSeminar].title,
        start: Utils.convertDateStringToDate(baseContext.selectedSeance.start_date).getTime(),
        end: Utils.convertDateStringToDate(baseContext.selectedSeance.end_date).getTime()
      }, true);
      this.session.seances.push(this.selectedSeance);
    }
    jQuery('#modal_seance_seminar').modal('hide');
  }

  isValidChamps() {
    if (this.session.name && this.session.start_date && this.session.end_date && this.session.year_university && this.session.id_level
      && this.session.seances.length > 0) {
      return true;
    }
    return false;
  }

  submitFormSession() {
    this.submitted = true;
    if (!this.isValidChamps()) {
      swal('Warning', 'Vérifier vos champs', 'warning');
      return;
    }


    if (!this.session.id_seminar_session) {
      this.busy = this.mseminarService.addSession(this.session)
        .subscribe(
          (data) => {
            swal('Succées', 'Ajout session avec succées', 'success');
            this.router.navigate(['/mini-seminar/sessions']);
          },
          (error) => {

          }
        )
    } else {
      this.busy = this.mseminarService.updateSession(this.session)
        .subscribe(
          (data) => {
            swal("Succées", "Edit session avec succées", 'success');
            this.router.navigate(['/mini-seminar/sessions']);
          },
          (error) => {
            swal('Erreur', 'une erreur est survenue', 'error');
          }
        )
    }
  }

  private getAllSeminars() {
    this.busy = this.mseminarService
      .getAllSeminars()
      .subscribe(
        (data) => {
          this.seminars = data;
        },
        (error) => {

        }
      )
  }
}
