import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import {FormSeminarComponent} from "./form-seminar/form-seminar.component";
import {ListSeminarComponent} from "./list-seminar/list-seminar.component";
import {ListSessionSeminarComponent} from "./list-session-seminar/list-session-seminar.component";
import {FormSessionSeminarComponent} from "./form-session-seminar/form-session-seminar.component";

const routes: Routes = [
  {
    path: 'form-seminar',
    component: FormSeminarComponent
  },
  {
    path: 'list',
    component: ListSeminarComponent
  },
  {
    path: ':seminarId/edit',
    component: FormSeminarComponent
  },
  {
    path: 'sessions',
    component: ListSessionSeminarComponent
  },
  {
    path: 'sessions/add',
    component: FormSessionSeminarComponent
  },
  {
    path: 'sessions/:sessionId/edit',
    component: FormSessionSeminarComponent
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class ManageMseminarRoutingModule {
}
