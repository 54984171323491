/**
 * Created by Abbes on 08/09/2017.
 */
import {Injectable} from "@angular/core";
import {GenericService} from "./generic.service";
import {HttpClient} from "@angular/common/http";
import {catchError, map} from 'rxjs/operators';
import {StorageService} from "./storage.service";
import {City} from "../models/city";
import {Observable} from "rxjs/Observable";
import {Country} from "../models/country";
import {environment} from "../../../environments/environment";

@Injectable()
export class SharedService extends GenericService {

  constructor(private http: HttpClient, private storageService: StorageService) {
    super();
  }


  getAllTypes() {
    const url = environment.baseUrl + "/types";
    const headers = this.headers;
    return this.http.get<any>(url, {
      headers: headers
    })
      .pipe(catchError(this.handleErrors));
  }

  getAllMentions() {
    const url = environment.baseUrl + "/mentions";
    const headers = this.headers;
    return this.http.get<any>(url, {
      headers: headers
    })
      .pipe(catchError(this.handleErrors));
  }

  getAllResults() {
    const url = environment.baseUrl + "/results";
    const headers = this.headers;
    return this.http.get<any>(url, {
      headers: headers
    })
      .pipe(catchError(this.handleErrors));
  }

  getAllUniversities() {
    const url = environment.baseUrl + "/universities";
    const headers = this.headers;
    return this.http.get<any>(url,
      {
        headers: headers
      })
      .pipe(catchError(this.handleErrors));
  }

  getAllLevels() {
    const url = environment.baseUrl + "/levels";
    const headers = this.headers;
    return this.http.get<any>(url,
      {
        headers: headers
      })
      .pipe(catchError(this.handleErrors));
  }

  getAllGrades() {
    const url = environment.baseUrl + "/grades";
    const headers = this.headers;
    return this.http.get<any>(url,
      {
        headers: headers
      })
      .pipe(catchError(this.handleErrors));
  }

  getAllHopitaux() {
    const url = environment.baseUrl + "/hopitaux";
    const headers = this.headers;
    return this.http.get<any>(url,
      {
        headers: headers
      })
      .pipe(catchError(this.handleErrors));
  }

  getAllServices() {
    const url = environment.baseUrl + "/services";
    const headers = this.headers;
    return this.http.get<any>(url,
      {
        headers: headers
      })
      .pipe(catchError(this.handleErrors));
  }


  getAllCredits() {
    const url = environment.baseUrl + "/credits";
    const headers = this.headers;
    return this.http.get<any>(url,
      {
        headers: headers
      })
      .pipe(catchError(this.handleErrors));
  }

  getAllCountries(): Observable<Country[]> {
    const url = environment.baseUrl + "/geo/countries";
    const countries = this.getAllCountriesFromStorage();
    const headers = this.headers;
    if (countries) {
      return Observable.create(observer => {
        observer.next(countries);
        observer.complete();
      })
    } else {
      return this.http.get<any>(url, {
        headers: headers
      }).pipe(map(res => {
        this.saveAllCountriesFromStorage(res);
        return res;
      }), catchError(this.handleErrors));
    }
  }

  getCitiesByCountry(countryId: string): Observable<City[]> {
    const url = environment.baseUrl + "/geo/countries/" + countryId + "/cities";
    const tunisCities = this.getTunisCitiesFromStorage();
    const headers = this.headers;
    if (countryId.localeCompare('TUN') === 0 && tunisCities) {
      return Observable.create(observer => {
        observer.next(tunisCities);
        observer.complete();
      })
    } else {
      return this.http.get<any>(url, {
        headers: headers
      }).pipe(
        map(res => {
          if (countryId.localeCompare('TUN') === 0) {
            this.saveTunisCitiesFromStorage(res);
          }
          return res;
        }), catchError(this.handleErrors));
    }
  }

  getAllFonctionTypes() {
    const url = environment.baseUrl + "/fonction_types";
    const headers = this.headers;
    return this.http.get<any>(url, {
      headers: headers
    })
      .pipe(catchError(this.handleErrors));
  }

  getAllCountriesFromStorage() {
    return <Array<Country>> this.storageService.read('countries');
  }

  saveAllCountriesFromStorage(countries: Array<Country>) {
    this.storageService.write('countries', countries);
  }

  getTunisCitiesFromStorage() {
    return <Array<City>> this.storageService.read('tunis-cities');
  }

  saveTunisCitiesFromStorage(cities: Array<City>) {
    this.storageService.write('tunis-cities', cities);
  }

  getAllDomaines() {
    const url = environment.baseUrl + "/domaines";
    const headers = this.headers;
    return this.http.get<any>(url, {
      headers: headers
    })
      .pipe(catchError(this.handleErrors));
  }

  getAllDisciplines() {
    const url = environment.baseUrl + "/disciplines";
    const headers = this.headers;
    return this.http.get<any>(url, {
      headers: headers
    })
      .pipe(catchError(this.handleErrors));
  }
}
