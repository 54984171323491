import {Component, OnInit} from '@angular/core';
import {Subscription} from "rxjs/Rx";
import {PostGraduateTrainingService} from "../../shared/services/post-graduate-training.service";
import {Formation} from "../../shared/models/formation";
import {Utils} from "../../shared/utils";
import {SessionFormation} from "../../shared/models/session_formation";
import {PreInscriptionFormation} from "../../shared/models/pre_inscription_formation";
import * as XLSX from "xlsx";
import {ActivatedRoute, Router} from "@angular/router";
import {UserService} from "../../shared/services/user.service";

declare var swal: any;
type AOA = any[][];

class Line {
  public firstName: string;
  public lastName: string;
  public identifiant: string;
}

@Component({
  selector: 'app-list-training',
  templateUrl: './list-training.component.html',
  styleUrls: ['./list-training.component.css']
})
export class ListTrainingComponent implements OnInit {

  busy: Subscription;
  formations: Formation[] = [];

  isAdmin: boolean;

  sessionId: number;

  sessionFormation: SessionFormation = new SessionFormation();
  private preInscriptionsFormation: PreInscriptionFormation[] = [];
  results: AOA = [["Numero", "Nom", "Prenom", "CIN/IDENT"]];
  results_accepted: AOA = [["Numero", "Nom", "Prenom", "CIN/IDENT"]];
  results_refused: AOA = [["Numero", "Nom", "Prenom", "CIN/IDENT"]];
  results_attente: AOA = [["Numero", "Nom", "Prenom", "CIN/IDENT"]];


  constructor(private postGraduateTrainingService: PostGraduateTrainingService,
              private route: ActivatedRoute,
              private router: Router,
              private userService: UserService) {
    this.sessionId = parseInt(this.route.snapshot.paramMap.get("sessionId"), 0);
    this.isAdmin = this.userService.checkIfAdminHasRole(1);
  }

  ngOnInit() {
    if (!this.sessionId) {
      this.getAllFormation();
    } else {
      this.getSessionById()
    }
  }


  getSessionById() {
    this.busy = this.postGraduateTrainingService.getSessionById(this.sessionId)
      .subscribe(
        (data: SessionFormation) => {
          this.sessionFormation = data;
          if (data.formations) {
            this.formations = Utils.convertToFormation(data.formations);
          }
          Utils.initializeDataTables('datatable-basic', 20, 8);
        },
        (error) => {

        }
      )
  }

  getAllFormation() {
    const baseContext = this;
    this.busy = this.postGraduateTrainingService.getAllFormation()
      .subscribe(
        (data) => {
          this.formations = data;
          Utils.initializeDataTables('datatable-basic', 20, 8);
        },
        (error) => {

        }
      )
  }

  deletePostFormation(index: number) {
    const baseContext = this;
    swal({
        title: "Vous êtes sûr?",
        text: "Cet formation va être supprimer définitivement!",
        type: "warning",
        showCancelButton: true,
        confirmButtonColor: "#EF5350",
        confirmButtonText: "Oui, supprimer!",
        cancelButtonText: "Non, annuler!",
        closeOnConfirm: true,
        closeOnCancel: true
      },
      function (isConfirm) {
        if (isConfirm) {
          baseContext.busy = baseContext.postGraduateTrainingService.deleteFormation(baseContext.formations[index].id_formation)
            .subscribe(data => {
              baseContext.formations.splice(index, 1);
              swal({
                title: "Supprimé!",
                text: "Cet formation est supprimé.",
                confirmButtonColor: "#66BB6A",
                type: "success"
              });
              Utils.initializeDataTables('datatable-basic', 20, 8);
            }, error => {
              swal("Attention !", "Vous ne pouvez pas supprimer cette formation", "warning");
            });
        } else {
          swal({
            title: "Annulé",
            text: "Vous avez annulé cette action",
            confirmButtonColor: "#2196F3",
            type: "error"
          });
        }
      });
  }

  downloadExcel(i, sessionId, formationId) {
    this.loadCandidatsByFormation(i, sessionId, formationId);
  }

  loadCandidatsByFormation(i, sessionId, formationId) {
    this.results_accepted = [["Numero", "Nom", "Prenom", "CIN/IDENT", "Type du Condidat", "STATUS", "Date de l'inscription"]];
    this.results_refused = [["Numero", "Nom", "Prenom", "CIN/IDENT", "Type du Condidat", "STATUS", "Date de l'inscription"]];
    this.results_attente = [["Numero", "Nom", "Prenom", "CIN/IDENT", "Type du Condidat", "STATUS", "Date de l'inscription"]];
    let numnero_accepted = 0;
    let numnero_refused = 0;
    let numero_attente = 0;
    this.busy = this.postGraduateTrainingService.getInscriptionsByFormation(sessionId, formationId)
      .subscribe(
        (data: PreInscriptionFormation[]) => {
          console.log(data);
          this.preInscriptionsFormation = data;
          console.log(this.preInscriptionsFormation);
          let res = -1;
          for (let i = 0; i < this.preInscriptionsFormation.length; i++) {
            // [["ID", "NOM", "PRENOM", "CIN/IDENT", "NIVEAU SCHOLAR","CLASSEMENT", "NIVEAU PLATEFORME", "NIVEAU PRECENDANT", "STATUS", "REMARQUE"]]
            res = this.preInscriptionsFormation[i].final;
            if (res == 1) {
              numnero_accepted++;
              this.pushit(this.results_accepted, numnero_accepted, this.preInscriptionsFormation[i]);
            }
            else if (res == 0) {
              numero_attente++;
              this.pushit(this.results_attente, numero_attente, this.preInscriptionsFormation[i]);
            } else if (res == 2) {
              numnero_refused++;
              this.pushit(this.results_refused, numnero_refused, this.preInscriptionsFormation[i]);
            }
          }
          console.log(this.results_accepted);

          /* generate worksheet */
          const sheet_accepted: XLSX.WorkSheet = XLSX.utils.aoa_to_sheet(this.results_accepted);
          const sheet_attente: XLSX.WorkSheet = XLSX.utils.aoa_to_sheet(this.results_attente);
          const sheet_refused: XLSX.WorkSheet = XLSX.utils.aoa_to_sheet(this.results_refused);
          /* generate workbook and add the worksheet */
          const wb: XLSX.WorkBook = XLSX.utils.book_new();
          XLSX.utils.book_append_sheet(wb, sheet_accepted, "Les étudiants acceptés");
          XLSX.utils.book_append_sheet(wb, sheet_attente, "Les étudiants en attente");
          XLSX.utils.book_append_sheet(wb, sheet_refused, "Les étudiants refusés");
          /* save to file */
          XLSX.writeFile(wb, "Resultats " + this.formations[i].label + ".xlsx");

        },
        (error) => {
          if (error.status === 401) {
            swal("Erreur", "Vous n'avez pas droit d'accéeder à cette formation", "error");
          }
        }
      )
  }

  pushit(tab: AOA[], number, demande: PreInscriptionFormation) {
    console.log(demande);
    let student = demande.student;
    let teacher = demande.teacher;
    tab.push([
      number,
      student ? student.last_name : teacher.last_name,
      student ? student.first_name : teacher.first_name,
      student ? student.cin ? student.cin.code : student.passport.code :
        teacher.cin ? teacher.cin.code : teacher.passport.code,
      student ? (student.medecin ? "Résident" : "Scientifique") : teacher.id_Specialite === 60 ? 'Médecin de Famille' :
        teacher.id_Specialite === 61 ? 'Généraliste' : 'Spécialiste en ' + teacher.specialite.nom,
      demande.status === 1 ? "ACCEPTE" : demande.status === 3 ? "REFUSEE" : "ACCEPTABLE",
      demande.created_at,
    ])

  }

}
