/**
 * Created by AHMED on 04/08/2017.
 */
export class Reclamation {
  public id_Reclamation: number;
  public contenu: string; // compsed to typeStage+niveau+mois+années
  public type: string;
  public telephone: string;
  public status: number;
  public id_Enseignant: number;
  public created_at: string;
  public updated_at: string;


}
