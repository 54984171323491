/**
 * Created by Abbes on 25/08/2017.
 */
export class RegistrationYearUniversity {
  public id_Registration_University: number;
  public start_date: string;
  public end_date: string;
  public description: string;
  public year_university: string;
  public cycle: number;

  constructor(cycle: number) {
    this.cycle = cycle;
  }
}
