import { Component, OnInit } from '@angular/core';
import {StudentService} from "../../shared/services/student.service";
import {Utils} from "../../shared/utils";
import {Student} from "../../shared/models/student";
import {Subscription} from "rxjs/Subscription";
import {Teacher} from "../../shared/models/Teacher";
import {TeacherService} from "../../shared/services/teacher.service";
declare let jQuery : any;
declare let swal : any;
@Component({
  selector: 'app-list-non-confirmed-teacher',
  templateUrl: './list-non-confirmed-teacher.component.html',
  styleUrls: ['./list-non-confirmed-teacher.component.css']
})
export class ListNonConfirmedTeacherComponent implements OnInit {
  teachers : Teacher[] =[];
  busy: Subscription;
  private selectedTeacher: Teacher =new Teacher();
  private initialEmail: string;

  constructor(private teacherService: TeacherService) { }

  ngOnInit() {
    this.getNonConfirmedTeachers();
  }

  private getNonConfirmedTeachers() {
    this.busy = this.teacherService.getAllUnconfirmedTeachers().subscribe(data => {
      this.teachers = data;
      console.log(this.teachers);
      Utils.initializeDataTables('datatable-basic',200, 5);

    });
  }

  openModalEmail(index: number) {
    this.initialEmail = this.teachers[index].email;
    this.selectedTeacher = this.teachers[index];
    jQuery("#modal_form_vertical").modal();

  }
  sendMailConfirmation(i) {
    this.selectedTeacher = this.teachers[i];

    this.busy =   this.teacherService.sendConfirmationEmail(this.selectedTeacher.id_Teacher)
      .subscribe(data => {
        swal('Success', 'Email de confirmation envoyé', 'success');

        Utils.initializeDataTables('datatable-basic',200, 5);
      },(error) => {
        swal('Error', 'Erreur, Email non envoyé', 'error');

      });

  }

  changeEMail() {
    this.busy =   this.teacherService.updateTeacherEmail(this.selectedTeacher.email,this.selectedTeacher.id_Teacher)
      .subscribe(data => {
        swal('Success', 'Email mis à jour avec succèes', 'success');

        Utils.initializeDataTables('datatable-basic',200, 5);
      },(error) => {
        this.selectedTeacher.email=this.initialEmail;

        swal('Error', 'Erreur, Contactez un administrateur.', 'error');

      });
    jQuery("#modal_form_vertical").modal('toggle');

  }

  deleteTeacher(index: number) {
    const baseContext = this;
    const teacher: Teacher = this.teachers[index];
    swal({
        title: "Vous êtes sûr?",
        text: "Cet enseignant va être supprimé définitivement!",
        type: "warning",
        showCancelButton: true,
        confirmButtonColor: "#EF5350",
        confirmButtonText: "Oui, supprimer!",
        cancelButtonText: "Non, annuler!",
        closeOnConfirm: true,
        closeOnCancel: true
      },
      function (isConfirm) {
        if (isConfirm) {
          baseContext.busy = baseContext.teacherService.deleteTeacher(teacher.id_Teacher).subscribe(data => {
            baseContext.teachers.splice(index, 1);
            swal({
              title: "Supprimé!",
              text: "Cet enseignant est supprimé.",
              confirmButtonColor: "#66BB6A",
              type: "success"
            });
          }, error => {

          });
        } else {
          swal({
            title: "Annulé",
            text: "Vous avez annulé cette action",
            confirmButtonColor: "#2196F3",
            type: "error"
          });
        }
      });
  }

}
