import {Injectable} from "@angular/core";
import {GenericService} from "./generic.service";
import {HttpClient} from "@angular/common/http";
import {catchError} from 'rxjs/operators';
import {StorageService} from "./storage.service";
import {Formation} from "../models/formation";
import {Teacher} from "../models/Teacher";
import {SessionFormation} from "../models/session_formation";
import {environment} from "../../../environments/environment";

@Injectable()
export class PostGraduateTrainingService extends GenericService {

  constructor(private http: HttpClient, private storageService: StorageService) {
    super();
  }


  getAllTypesFormation() {
    const url = environment.baseUrl + "/admin/formation/types";
    const headers = this.headers.set("Authorization", "Bearer " + this.storageService.read("admin-token"));
    return this.http.get<any>(url,
      {
        headers: headers
      }
    )
      .pipe(catchError(this.handleErrors));
  }

  getAllFormationNiveaux() {
    const url = environment.baseUrl + "/admin/formation/niveaux";
    const headers = this.headers.set("Authorization", "Bearer " + this.storageService.read("admin-token"));
    return this.http.get<any>(url,
      {
        headers: headers
      }
    )
      .pipe(catchError(this.handleErrors));
  }

  getAllFormation() {
    const url = environment.baseUrl + "/admin/formation";
    const headers = this.headers.set("Authorization", "Bearer " + this.storageService.read("admin-token"));
    return this.http.get<any>(url,
      {
        headers: headers
      }
    )
      .pipe(catchError(this.handleErrors));
  }

  addFormation(formation: Formation) {
    const url = environment.baseUrl + "/admin/formation/add";
    const headers = this.headers.set("Authorization", "Bearer " + this.storageService.read("admin-token"));
    return this.http.post<any>(url,
      formation,
      {
        headers: headers
      }
    )
      .pipe(catchError(this.handleErrors));
  }

  deleteFormation(formationId: number) {
    const url = environment.baseUrl + "/admin/formation/" + formationId + "/delete";
    const headers = this.headers.set("Authorization", "Bearer " + this.storageService.read("admin-token"));
    return this.http.delete<any>(url,
      {
        headers: headers
      }
    )
      .pipe(catchError(this.handleErrors));
  }

  getAllDemandsTraining(sessionId: number) {
    const url = environment.baseUrl + "/admin/session-formation/" + sessionId + "/list-demands";
    const headers = this.headers.set("Authorization", "Bearer " + this.storageService.read("admin-token"));
    return this.http.get(url,
      {
        headers: headers
      }
    )
      .pipe(catchError(this.handleErrors));
  }

  verifyValidations(sessionFormationId: number) {
    const url = environment.baseUrl + "/admin/session-formation/" + sessionFormationId + "/verify-validations";
    const headers = this.headers.set("Authorization", "Bearer " + this.storageService.read("admin-token"));
    return this.http.get(url,
      {
        headers: headers
      }
    )
      .pipe(catchError(this.handleErrors));
  }

  affectCandidatesFormations(sessionFormationId: number) {
    const url = environment.baseUrl + "/admin/session-formation/" + sessionFormationId + "/affect-candidates";
    const headers = this.headers.set("Authorization", "Bearer " + this.storageService.read("admin-token"));
    return this.http.get(url,
      {
        headers: headers
      }
    )
      .pipe(catchError(this.handleErrors));
  }

  getSessionFormationByYearUniversity(year_university: string) {
    const url = environment.baseUrl + '/student/session-formation/year-university';
    const headers = this.headers.set("Authorization", "Bearer " + this.storageService.read('admin-token'));
    return this.http.post(url, {
        year_university: year_university
      }, {
        headers: headers
      }
    ).pipe(catchError

      (
        this
          .handleErrors
      )
    )
  }

  sendMailToCoordinateurs(sessionFormationId: number, coordinateurs: Teacher[]) {
    const url = environment.baseUrl + '/admin/session-formation/' + sessionFormationId + '/send-notification-teachers';
    const headers = this.headers.set("Authorization", "Bearer " + this.storageService.read('admin-token'));
    return this.http.post(url, coordinateurs, {
        headers: headers
      }
    ).pipe(catchError

      (
        this
          .handleErrors
      )
    )
  }

  modifyDateChoicesPOSTFormation(sessionFormation: SessionFormation) {
    const url = environment.baseUrl + '/student/session-formation/year-university';
    const headers = this.headers.set("Authorization", "Bearer " + this.storageService.read('admin-token'));
    return this.http.put(url,
      sessionFormation
      , {
        headers: headers
      }
    ).pipe(catchError

      (
        this
          .handleErrors
      )
    )
  }

  getFormationById(formationId: number) {
    const url = environment.baseUrl + "/admin/formation/" + formationId;
    const headers = this.headers.set("Authorization", "Bearer " + this.storageService.read("admin-token"));
    return this.http.get(url,
      {
        headers: headers
      }
    )
      .pipe(catchError(this.handleErrors));
  }

  editFormation(formation: Formation) {
    const url = environment.baseUrl + "/admin/formation/edit";
    const headers = this.headers.set("Authorization", "Bearer " + this.storageService.read("admin-token"));
    return this.http.put(url,
      formation,
      {
        headers: headers
      }
    )
      .pipe(catchError(this.handleErrors));
  }

  getInscriptionsByFormation(sessionId, formationId) {
    const url = environment.baseUrl + '/admin/session-formation/' + sessionId + '/formation/' + formationId + '/inscriptions';

    const headers = this.headers.set("Authorization", "Bearer " + this.storageService.read('admin-token'));
    return this.http.get(url, {
      headers: headers
    }).pipe(
      catchError(this.handleErrors)
    );
  }

  getPreInscriptionsByFormation(sessionId, id_formation) {
    const url = environment.baseUrl + '/admin/session-formation/' + sessionId + '/formation/' + id_formation + '/preInscriptions';

    const headers = this.headers.set("Authorization", "Bearer " + this.storageService.read('admin-token'));
    return this.http.get(url, {
      headers: headers
    }).pipe(
      catchError(this.handleErrors)
    );
  }


  toggleInscription(id_preinscription: number) {

    const url = environment.baseUrl + '/admin/formation/' + id_preinscription + '/toggleInscription';

    const headers = this.headers.set("Authorization", "Bearer " + this.storageService.read('admin-token'));
    return this.http.get(url, {
      headers: headers
    }).pipe(
      catchError(this.handleErrors)
    );
  }

  //Retourne la liste des inscriptions qui figurent dans la table Inscription_formation : ceux qui ont été validés par la scolarité
  getValidatedInscriptionsByFormation(sessionId: number, formationId: number) {

    const url = environment.baseUrl + '/admin/session-formation/' + sessionId + '/formation/' + formationId + '/validatedInscriptions';
    const headers = this.headers.set("Authorization", "Bearer " + this.storageService.read('admin-token'));

    return this.http.get(url, {
      headers: headers
    }).pipe(
      catchError(this.handleErrors)
    );
  }

  addCandidateByIdent(sessionId, formationId: number, identifiant: string) {
    const url = environment.baseUrl + '/admin/session-formation/' + sessionId + '/formation/' + formationId + '/add-candidat';
    const headers = this.headers.set("Authorization", "Bearer " + this.storageService.read('admin-token'));

    return this.http.post(url, {
      identifiant: identifiant
    }, {
      headers: headers
    }).pipe(
      catchError(this.handleErrors)
    );
  }

  getAllSessions() {
    const url = environment.baseUrl + '/admin/session-formation/list';
    const headers = this.headers.set("Authorization", "Bearer " + this.storageService.read('admin-token'));

    return this.http.get(url, {
      headers: headers
    }).pipe(
      catchError(this.handleErrors)
    );
  }

  addSessionFormation(session: SessionFormation) {
    const url = environment.baseUrl + '/admin/session-formation/add';
    const headers = this.headers.set("Authorization", "Bearer " + this.storageService.read('admin-token'));

    return this.http.post(url, session, {
      headers: headers
    }).pipe(
      catchError(this.handleErrors)
    );
  }

  deleteSessionFormationById(sessionFormationId: number) {
    const url = environment.baseUrl + '/admin/session-formation/' + sessionFormationId + "/delete";
    const headers = this.headers.set("Authorization", "Bearer " + this.storageService.read('admin-token'));

    return this.http.delete(url, {
      headers: headers
    }).pipe(
      catchError(this.handleErrors)
    );
  }

  getSessionById(sessionId: number) {
    const url = environment.baseUrl + '/admin/session-formation/' + sessionId;
    const headers = this.headers.set("Authorization", "Bearer " + this.storageService.read('admin-token'));

    return this.http.get(url, {
      headers: headers
    }).pipe(
      catchError(this.handleErrors)
    );
  }

  editSessionFormation(session: SessionFormation) {
    const url = environment.baseUrl + '/admin/session-formation/' + session.id_session_formation + '/edit';
    const headers = this.headers.set("Authorization", "Bearer " + this.storageService.read('admin-token'));

    return this.http.put(url, session, {
      headers: headers
    }).pipe(
      catchError(this.handleErrors)
    );
  }

  changeStautsInscription(inscriptionId: any, status: any) {
    const url = environment.baseUrl + '/admin/inscription-formation/' + inscriptionId + '/change/' + status;
    const headers = this.headers.set("Authorization", "Bearer " + this.storageService.read('admin-token'));

    return this.http.get(url, {
      headers: headers
    }).pipe(
      catchError(this.handleErrors)
    );
  }
}
