import {Component, OnInit} from '@angular/core';
import * as XLSX from 'xlsx';
import {Utils} from "../../shared/utils";
import {AdminService} from "../../shared/services/admin.service";
import {Subscription} from "rxjs";
import {Level} from "../../shared/models/level";
import {SharedService} from "../../shared/services/shared.service";
import {Credit} from "../../shared/models/credit";
import {Result} from "../../shared/models/result";

declare var jQuery: any;
declare var swal: any;
type AOA = any[][];

@Component({
  selector: 'app-update-student-infos',
  templateUrl: './update-student-infos.component.html',
  styleUrls: ['./update-student-infos.component.css']
})
export class UpdateStudentInfosComponent implements OnInit {

  data: AOA;

  dataLevel: AOA;

  finalData: ItemExcelUpadteInfo[] = [];
  finalDataLevel: ItemExcelUpdateLevelInfo[] = [];

  levels: Level[] = [];

  credits: Credit[] = [];
  results: Result[] = [];

  selectedLevel: number;

  errorLogDataLevel: AOA = [["ID", "NOM", "PRENOM", "CIN/IDENT", "CLASSEMENT",
    "NIVEAU SCHOLAR", "NIVEAU PLATEFORME", "PHONE", "DOSSIER", "REMARQUE"]];

  busy: Subscription;

  constructor(private adminService: AdminService,
              private sharedService: SharedService) {
  }

  ngOnInit() {
    setTimeout(function () {
      jQuery('.file-styled').uniform({
        fileButtonClass: 'action btn bg-green-400'
      });
    }, 100);


    this.getAllLevels();
    this.getAllCredits();
    this.getAllResults();
  }


  getAllResults() {
    this.busy = this.sharedService.getAllResults()
      .subscribe(
        (data) => {
          this.results = data;
        },
        (error) => {

        }
      )
  }

  getAllCredits() {
    this.busy = this.sharedService.getAllCredits()
      .subscribe(
        (data) => {
          this.credits = data;
        },
        (error) => {

        }
      )
  }

  getAllLevels() {
    this.busy = this.sharedService.getAllLevels()
      .subscribe(
        (data) => {
          this.levels = data;
        },
        (error) => {

        }
      )
  }

  // EXCEL FORMAT : NOM , PRENOM , CIN/IDENT , NIVEAU , CLASSEMENT
  onFileChangeLevel(evt: any) {
    /* wire up file reader */
    const target: DataTransfer = <DataTransfer>(evt.target);
    if (target.files.length !== 1) throw new Error('Cannot use multiple files');
    const reader: FileReader = new FileReader();
    reader.onload = (e: any) => {
      /* read workbook */
      const bstr: string = e.target.result;
      const wb: XLSX.WorkBook = XLSX.read(bstr, {type: 'binary'});

      /* grab first sheet */
      const wsname: string = wb.SheetNames[0];
      const ws: XLSX.WorkSheet = wb.Sheets[wsname];

      /* save data */
      this.dataLevel = <AOA>(XLSX.utils.sheet_to_json(ws, {header: 1}));
    };
    reader.readAsBinaryString(target.files[0]);
  }

  onFileChange(evt: any) {
    /* wire up file reader */
    const target: DataTransfer = <DataTransfer>(evt.target);
    if (target.files.length !== 1) throw new Error('Cannot use multiple files');
    const reader: FileReader = new FileReader();
    reader.onload = (e: any) => {
      /* read workbook */
      const bstr: string = e.target.result;
      const wb: XLSX.WorkBook = XLSX.read(bstr, {type: 'binary'});

      /* grab first sheet */
      const wsname: string = wb.SheetNames[0];
      const ws: XLSX.WorkSheet = wb.Sheets[wsname];

      /* save data */
      this.data = <AOA>(XLSX.utils.sheet_to_json(ws, {header: 1}));
    };
    reader.readAsBinaryString(target.files[0]);
  }

  sendData() {

    if (!this.isValidData()) {
      return;
    }

    this.prepareData();
  }

  isValidData() {
    return true;
  }

  updateLevelInfo() {
    this.finalDataLevel = [];

    for (let i = 1; i < this.dataLevel.length; i++) {
      if (this.dataLevel[i].length > 0) {
        const itemLevelFinal = new ItemExcelUpdateLevelInfo();
        if (!this.validLigneLevelInfo(this.dataLevel[i])) {
          console.log("Error in ligne " + i);
          swal('Erreur', "Erreur dans la ligne " + i, "error");
          return;
        }
        itemLevelFinal.identifiant = this.dataLevel[i][0];
        itemLevelFinal.lastName = this.dataLevel[i][1];
        itemLevelFinal.firstName = this.dataLevel[i][2];
        itemLevelFinal.decision = this.dataLevel[i][3];
        itemLevelFinal.classement = this.dataLevel[i][4];
        this.finalDataLevel.push(itemLevelFinal);
      }
    }

    this.busy = this.adminService.updateStudentLevelInfo(this.finalDataLevel)
      .subscribe(
        (data) => {
          swal('Succées', 'Les niveaux sont mis à jours avec succées', "success");
          console.log(data);
        },
        (error) => {
          if (error.status === 400) {
            this.exportErrorExcel(error.error.error);
          }

        }
      )
  }

  prepareData() {
    this.finalData = [];
    let itemFinal: ItemExcelUpadteInfo;
    // console.log(this.data);
    for (let i = 1; i < this.data.length; i++) {
      if (this.data[i].length > 0) {

        if (!this.validLigneLevelInfo(this.data[i])) {
          console.log("Error in ligne " + i);
          swal('Erreur', "Erreur dans la ligne " + i, "error");
          return;
        }
        itemFinal = new ItemExcelUpadteInfo();
        itemFinal.identifiant = this.data[i][0];
        if (this.data[i][4]) {
          itemFinal.id_credit = Utils.getIdByString(this.data[i][4], 'id_credit', 'label', this.credits);
          console.log(this.data[i][4]);
          console.log(itemFinal);
        }
        itemFinal.id_result = Utils.getIdByString(this.data[i][3], 'id_result', 'label', this.results);
        itemFinal.first_name = this.data[i][2];
        itemFinal.last_name = this.data[i][1];
        itemFinal.classement = this.data[i][5];
        this.finalData.push(itemFinal);
      }
    }

    this.busy = this.adminService.updateStudentInfo(this.selectedLevel, this.finalData)
      .subscribe(
        (data) => {
          swal('Success', 'Data mise à jour avec succées', 'success');
        },
        (error) => {
          this.exportErrorExcel(error.error.error);
        }
      );

  }

  private validLigneLevelInfo(dataLevelElement: any[]) {
    // || (!dataLevelElement[5] && dataLevelElement[3] !== "redoublant" && this.selectedLevel > 1)
    return !(!dataLevelElement[0] || !dataLevelElement[1] || !dataLevelElement[2] || !dataLevelElement[3]);

  }

  private exportErrorExcel(error: ErrorLogLevel[]) {
    this.errorLogDataLevel = [["ID", "NOM", "PRENOM", "CIN/IDENT", "CLASSEMENT",
      "NIVEAU SCHOLAR", "NIVEAU PLATEFORME", "PHONE", "DATE DE NAISSANCE", "ANNEE ACCESS ETUDE", "DOSSIER", "REMARQUE"]];
    console.log(error);
    for (let i = 0; i < error.length; i++) {
      this.errorLogDataLevel.push([
        error[i].idStudent,
        error[i].lastName,
        error[i].firstName,
        error[i].identifiant,
        error[i].classement,
        Utils.getLabelNiveau(error[i].levelScholar),
        Utils.getLabelNiveau(error[i].levelPlatform),
        error[i].phone,
        error[i].birthday,
        error[i].study_access_year,
        Utils.getStatusDossier(error[i].dossier_status, error[i].administration_review),
        error[i].message
      ])
    }

    /* generate worksheet */
    const ws: XLSX.WorkSheet = XLSX.utils.aoa_to_sheet(this.errorLogDataLevel);
    /* generate workbook and add the worksheet */
    const wb: XLSX.WorkBook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, "Sheet1");
    /* save to file */
    XLSX.writeFile(wb, "ProblémeUpdateNiveau.xlsx");
  }


}

export class ItemExcelUpadteInfo {
  public identifiant: string;
  public first_name: string;
  public last_name: string;
  public id_result: number;
  public id_credit: number;
  public classement: number;

}

export class ErrorLogLevel {
  public firstName: string;
  public lastName: string;
  public identifiant: string;
  public levelScholar: number;
  public levelPlatform: number;
  public phone: string;
  public idStudent: number;
  public message: string;
  public classement: number;
  public dossier_status: number;
  public administration_review: number;
  public birthday: string;
  public study_access_year: string;
}

export class ItemExcelUpdateLevelInfo {
  public firstName: string;
  public lastName: string;
  public identifiant: string;
  public classement: number;
  public decision: string;
}
