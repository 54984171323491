import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';

import {ManageMseminarRoutingModule} from './manage-mseminar-routing.module';
import {FormSeminarComponent} from './form-seminar/form-seminar.component';
import {SharedModule} from "../shared/shared.module";
import { ListSeminarComponent } from './list-seminar/list-seminar.component';
import { ListSessionSeminarComponent } from './list-session-seminar/list-session-seminar.component';
import { FormSessionSeminarComponent } from './form-session-seminar/form-session-seminar.component';

@NgModule({
  imports: [
    CommonModule,
    SharedModule,
    ManageMseminarRoutingModule
  ],
  declarations: [FormSeminarComponent, ListSeminarComponent, ListSessionSeminarComponent, FormSessionSeminarComponent]
})
export class ManageMseminarModule {
}
