import {Component, OnInit} from '@angular/core';
import {SeminarSession} from "../../shared/models/mseminar/SeminarSession";
import {MseminarService} from "../../shared/services/mseminar.service";
import {Utils} from "../../shared/utils";
import {Subscription} from "rxjs";
import * as XLSX from "xlsx";

declare var swal: any;

@Component({
  selector: 'app-list-session-seminar',
  templateUrl: './list-session-seminar.component.html',
  styleUrls: ['./list-session-seminar.component.css']
})
export class ListSessionSeminarComponent implements OnInit {

  sessions: SeminarSession[] = [];

  contentSheet: string[][] = [['NUM', 'NOM', 'PRENOM', 'CIN/IDENT']];
  busy: Subscription;

  constructor(private mseminarService: MseminarService) {
  }

  ngOnInit() {

    this.busy = this.mseminarService.getAllSessionsSeminar()
      .subscribe(
        (data: SeminarSession[]) => {
          this.sessions = data;

          Utils.initializeDataTables("list-seminar-session", 15);
        }
      )
  }

  deleteSessionSeminar(index: number) {
    const baseContext = this;
    swal({
        title: "Vous êtes sûr?",
        text: "Cette Session du Mini Séminaire à été supprimée définitivement!",
        type: "warning",
        showCancelButton: true,
        confirmButtonColor: "#EF5350",
        confirmButtonText: "Oui, supprimer!",
        cancelButtonText: "Non, annuler!",
        closeOnConfirm: true,
        closeOnCancel: true
      },
      function (isConfirm) {
        if (isConfirm) {
          baseContext.busy = baseContext.mseminarService.deleteSeminarSession(baseContext.sessions[index].id_seminar_session)
            .subscribe(
              (data) => {
                baseContext.sessions.splice(index, 1);
                swal("Succées", "Supression session mini séminaire avec succées", "success");
                Utils.initializeDataTables("list-seminar-session", 15);
              },
              (error) => {

              }
            );
        } else {
          swal({
            title: "Annulé",
            text: "Vous avez annulé cette action",
            confirmButtonColor: "#2196F3",
            type: "error"
          });
        }
      });
  }

  generateListInscrit(index: number) {
    const wb: XLSX.WorkBook = XLSX.utils.book_new();


    this.busy = this.mseminarService.getAllInscriptions(this.sessions[index].id_seminar_session)
      .subscribe(
        (data) => {
          var result: any = Utils.groupByM(data, function (item) {
            return [item.id_seance_seminar];
          });
          if (result.length > 0) {
            for (let i = 0; i < result.length; i++) {
              this.contentSheet = [['NUM', 'NOM', 'PRENOM', 'CIN/IDENT', 'SEMINAR', 'DATE']];
              for (let j = 0; j < result[i].length; j++) {
                this.contentSheet.push(
                  [
                    (j + 1),
                    result[i][j].student.last_name,
                    result[i][j].student.first_name,
                    Utils.getIdentifiantStudent(result[i][j].student),
                    result[i][j].seance.seminar.title,
                    result[i][j].seance.start_date
                  ]
                )
              }
              let sheet: XLSX.WorkSheet = XLSX.utils.aoa_to_sheet(this.contentSheet);
              console.log(result[i][0]);
              XLSX.utils.book_append_sheet(wb, sheet, 'seance_' + i);
            }

            XLSX.writeFile(wb, "InscriptionsSession-" + this.sessions[index].id_seminar_session + ".xlsx");
          }
        }
        ,
        (error) => {

        }
      )
  }
}
