import {Component, OnInit} from '@angular/core';
import {SessionFormation} from "../../shared/models/session_formation";
import {Subscription} from "rxjs";
import {Utils} from "../../shared/utils";
import {Formation} from "../../shared/models/formation";
import {PostGraduateTrainingService} from "../../shared/services/post-graduate-training.service";
import {ItemSessionFormation} from "../../shared/models/item-session-formation";
import {ActivatedRoute, Router} from "@angular/router";

declare var swal: any;

@Component({
  selector: 'app-session-post-form',
  templateUrl: './session-post-form.component.html',
  styleUrls: ['./session-post-form.component.css']
})
export class SessionPostFormComponent implements OnInit {

  busy: Subscription;
  session: SessionFormation;
  years: string[] = [];
  formations: Formation[] = [];


  sessionId: number;

  submitted: boolean;

  constructor(private postGraduateTrainingService: PostGraduateTrainingService,
              private router: Router,
              private route: ActivatedRoute) {
    this.session = new SessionFormation();
    if (this.router.url.indexOf('clone') === -1)
      this.sessionId = parseInt(this.route.snapshot.paramMap.get("sessionId"), 0);
  }

  ngOnInit() {
    this.years = Utils.getUniversityYears(2018);

    if (this.router.url.indexOf("clone") !== -1) {
      this.getSessionById(parseInt(this.route.snapshot.paramMap.get("sessionId"), 0));
    }

    this.getAllFormations();

    if (this.sessionId) {
      this.getSessionById(this.sessionId);
    }
  }

  private getAllFormations() {
    this.busy = this.postGraduateTrainingService.getAllFormation()
      .subscribe(
        (data: Formation[]) => {
          this.formations = data;
        }
      )
  }

  removeFormation() {
    this.session.formations.pop();
  }

  addFormation() {

    if (!this.isChampsFormationsValid()) {
      return;
    }
    this.session.formations.push(new ItemSessionFormation());
  }

  private isElementFormationValid(formation: ItemSessionFormation) {
    return formation.nb_places && formation.id_formation;
  }

  private isChampsFormationsValid() {
    for (let i = 0; i < this.session.formations.length; i++) {
      if (!this.isElementFormationValid(this.session.formations[i])) {
        return false;
      }
    }
    return true;
  }

  submitCreateSessionFormation() {

    if (!this.sessionId) {
      this.busy = this.postGraduateTrainingService.addSessionFormation(this.session)
        .subscribe(
          (data) => {
            swal("Succées", "Add Session Formation success", "success");
            this.router.navigate(["/post-graduated-training/sessions"]);
          },
          (error) => {
            swal("Erreur", "Add Session Formationn error", "error");
          }
        )
    } else {
      this.busy = this.postGraduateTrainingService.editSessionFormation(this.session)
        .subscribe(
          (data) => {
            swal("Succées", "Edit Session Formation success", "success");
            this.router.navigate(["/post-graduated-training/sessions"]);
          },
          (error) => {
            swal("Erreur", "Edit Session Formationn error", "error");
          }
        )
    }
  }

  private getSessionById(sessionId: number) {
    this.postGraduateTrainingService.getSessionById(sessionId)
      .subscribe(
        (data: SessionFormation) => {
          this.session = data;
        },
        (error) => {

        }
      )
  }
}
