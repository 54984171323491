import {SectionValidation} from "./models/section-validation";
import {ItemSessionFormation} from "./models/item-session-formation";
import {Formation} from "./models/formation";

/**
 * Created by Abbes on 30/06/2017.
 */
declare var jQuery: any;

/**
 * Created by Vyndee on 27/03/2017.
 */
export class Utils {


  public static zero(n: number) {
    if (n < 10) {
      return "0" + n;
    }
    return n;
  }

  static configDataTables() {
    /* jQuery.extend(jQuery.fn.dataTable.defaults, {
     autoWidth: false,
     columnDefs: [{
     orderable: false,
     width: '100px',
     targets: [5]
     }],
     dom: '<"datatable-header"fl><"datatable-scroll"t><"datatable-footer"ip>',
     language: {
     search: '<span>Filter:</span> _INPUT_',
     lengthMenu: '<span>Show:</span> _MENU_',
     paginate: {'first': 'First', 'last': 'Last', 'next': '&rarr;', 'previous': '&larr;'}
     },
     drawCallback: function () {
     jQuery(this).find('tbody tr').slice(-3).find('.dropdown, .btn-group').addClass('dropup');
     },
     preDrawCallback: function () {
     jQuery(this).find('tbody tr').slice(-3).find('.dropdown, .btn-group').removeClass('dropup');
     }
     });*/
  }


  private static groupBy(xs, key) {
    return xs.reduce(function (rv, x) {
      (rv[x[key]] = rv[x[key]] || []).push(x);
      return rv;
    }, {});
  }


  public static groupByM(array, f) {
    let groups = {};
    array.forEach(function (o) {
      var group = JSON.stringify(f(o));
      groups[group] = groups[group] || [];
      groups[group].push(o);
    });
    return Object.keys(groups).map(function (group) {
      return groups[group];
    })
  }


  static initializeDataTables(className, timout: number, columnNumber: number = null) {
    console.log("init Datatable");
    // Basic datatable
    if (jQuery.fn.DataTable.isDataTable('.' + className)) {
      jQuery('.' + className).dataTable().fnDestroy();
    }
    setTimeout(function () {
        const tableListStation = jQuery('.' + className);
        // Setting datatable defaults
        jQuery.extend(jQuery.fn.dataTable.defaults, {
          autoWidth: false,
          columnDefs: [{
            orderable: false,
            width: '100px',
            targets: [columnNumber - 1]
          }],
          dom: '<"datatable-header"fl><"datatable-scroll"t><"datatable-footer"ip>',
          language: {
            search: '<span>Filter:</span> _INPUT_',
            lengthMenu: '<span>Show:</span> _MENU_',
            paginate: {'first': 'First', 'last': 'Last', 'next': '&rarr;', 'previous': '&larr;'}
          },
          drawCallback: function () {
            jQuery(this).find('tbody tr').slice(-3).find('.dropdown, .btn-group').addClass('dropup');
          },
          preDrawCallback: function () {
            jQuery(this).find('tbody tr').slice(-3).find('.dropdown, .btn-group').removeClass('dropup');
          }
        });

        // console.log("order By " + orderBy);


        // Basic datatable
        tableListStation.DataTable({
          dom: 'Bfrtip',
          language: {
            "emptyTable": "..."
          },
          buttons: {
            dom: {
              button: {
                className: 'btn btn-default'
              }
            },
            buttons: [
              'excelHtml5',
              'pdfHtml5'
            ]
          }
          ,
          columnDefs: [{
            targets: [columnNumber - 1]
          }]
        });


        // Add placeholder to the datatable filter option
        jQuery('.dataTables_filter input[type=search]').attr('placeholder', 'Type to filter...');


        // Enable Select2 select for the length option
        jQuery('.dataTables_length select').select2({
          minimumResultsForSearch: Infinity,
          width: 'auto'
        });
      }
      , timout);


  }

  static convertDate(date: string) {
    if (date) {
      const HH = date.substring(11, 13);
      const mm = date.substring(14, 16);
      return date.substring(8, 10) + "/" + date.substring(5, 7) + "/" + date.substring(0, 4) + (HH && mm ? " " + HH + ":" + mm : "");
    }
    return null;
  }

  static convertTime(time: string) {
    if (time) {
      return time.substring(0, 5);
    }
    return null;
  }

  static convertDateStringToDate(date: string) {
    const HH = date.substring(11, 13);
    const mm = date.substring(14, 16);

    return new Date(+date.substring(0, 4), (+date.substring(5, 7) - 1), +date.substring(8, 10), +HH, +mm);
  }

  static convertRealDate(date: string, time?: string) {
    if (date) {
      console.log(date);

      console.log(date.substring(0, 2), date.substring(3, 5), date.substring(6, 10));
      if (!time) {
        return new Date(+date.substring(6, 10), (+date.substring(3, 5) - 1), +date.substring(0, 2));
      } else {
        return new Date(+date.substring(6, 10), (+date.substring(3, 5) - 1),
          +date.substring(0, 2), +time.substring(0, 2), +time.substring(3, 5));
      }
    }
    return null;
  }

  static getModalTemplate() {
    return '<div class="modal-dialog modal-lg" role="document">\n' +
      '  <div class="modal-content">\n' +
      '    <div class="modal-header">\n' +
      '      <div class="kv-zoom-actions btn-group">{toggleheader}{fullscreen}{borderless}{close}</div>\n' +
      '      <h6 class="modal-title">{heading} <small><span class="kv-zoom-title"></span></small></h6>\n' +
      '    </div>\n' +
      '    <div class="modal-body">\n' +
      '      <div class="floating-buttons btn-group"></div>\n' +
      '      <div class="kv-zoom-body file-zoom-content"></div>\n' + '{prev} {next}\n' +
      '    </div>\n' +
      '  </div>\n' +
      '</div>\n';
  }

  static getPreviewZoomButtonClasses() {
    return {
      toggleheader: 'btn btn-default btn-icon btn-xs btn-header-toggle',
      fullscreen: 'btn btn-default btn-icon btn-xs',
      borderless: 'btn btn-default btn-icon btn-xs',
      close: 'btn btn-default btn-icon btn-xs'
    };
  }

  static getPreviewZoomButtonIcons() {
    return {
      prev: '<i class="icon-arrow-left32"></i>',
      next: '<i class="icon-arrow-right32"></i>',
      toggleheader: '<i class="icon-menu-open"></i>',
      fullscreen: '<i class="icon-screen-full"></i>',
      borderless: '<i class="icon-alignment-unalign"></i>',
      close: '<i class="icon-cross3"></i>'
    };
  }

  static initializeUploadFile(url: string, token: string, className: string,
                              showRemove: boolean,
                              showUpload: boolean,
                              maxFileCount?: number,
                              initialData?: any[],
                              initialPreviewConfig?: InitialPreviewConfig[]) {
    jQuery(className).fileinput({
      uploadUrl: url, // server upload action
      uploadAsync: true,
      showRemove: showRemove,
      showUpload: showUpload,
      maxFileCount: maxFileCount,
      overwriteInitial: false,
      initialPreview: initialData,
      initialPreviewAsData: true,
      initialPreviewFileType: 'image', // image is the default and can be overridden in config below
      initialPreviewConfig: initialPreviewConfig,
      fileActionSettings: {
        removeIcon: '<i class="icon-bin"></i>',
        removeClass: 'btn btn-link btn-xs btn-icon',
        uploadIcon: '<i class="icon-upload"></i>',
        uploadClass: 'btn btn-link btn-xs btn-icon',
        zoomIcon: '<i class="icon-zoomin3"></i>',
        zoomClass: 'btn btn-link btn-xs btn-icon',
        downloadIcon: '<i class="icon-download"></i>',
        downloadClass: 'btn btn-link btn-xs btn-icon',
        indicatorNew: '<i class="icon-file-plus text-slate"></i>',
        indicatorSuccess: '<i class="icon-checkmark3 file-icon-large text-success"></i>',
        indicatorError: '<i class="icon-cross2 text-danger"></i>',
        indicatorLoading: '<i class="icon-spinner2 spinner text-muted"></i>',
      },
      purifyHtml: true, // this by default purifies HTML data for preview
      initialCaption: "Pas encore de fichier selectionné",
      previewZoomButtonClasses: Utils.getPreviewZoomButtonClasses(),
      previewZoomButtonIcons: Utils.getPreviewZoomButtonIcons(),
      ajaxSettings: {headers: {'Authorization': 'Bearer ' + token}},
    })
  }

  static convertDateServer(date: string) {
    const HH = date.substring(11, 13);
    const mm = date.substring(14, 16);
    return date.substring(6, 10) + "-" + date.substring(3, 5) + "-" + date.substring(0, 2) + (HH && mm ? " " + HH + ":" + mm : "");
  }

  static loadTypeFromExtension(ext: string) {
    if (ext.toLowerCase().match(/(avi|mpg|mkv|mov|mp4|3gp|webm|wmv)$/i)) {
      return "video";
    }
    if (ext.toLowerCase().match(/(pdf)$/i)) {
      return "pdf";
    }
  }

  static loadFileTypeFromExtension(ext: string) {
    if (ext.toLowerCase().match(/(avi|mpg|mkv|mov|mp4|3gp|webm|wmv)$/i)) {
      return "video/" + ext;
    }
    if (ext.toLowerCase().match(/(pdf)$/i)) {
      return "pdf";
    }
  }

  static getYears(minYear: number) {
    const years: number[] = [];

    for (let i = minYear; i <= new Date().getFullYear(); i++) {
      years.push(i);
    }
    return years;
  }

  static getUniversityYears(minYear: number) {
    const uniYears: string[] = [];

    for (let i = minYear; i <= new Date().getFullYear(); i++) {
      let j = i + 1;
      uniYears.push(i + "-" + j);
    }

    return uniYears;
  }

  static verifyNewStudent(study_access_year: string) {
    if (!study_access_year) {
      return false;
    }
    const year = parseInt(study_access_year.substring(0, 4), 0);
    return year === new Date().getFullYear();
  }

  static getStatusSection(validations: any[], id_section: number) {

    if (!validations) {
      return null;
    }
    const result = jQuery.grep(validations, function (e) {
      return e.id_section === id_section;
    });


    if (!result || result.length === 0) {
      return null;
    } else {
      return result[0];
    }
  }

  static getNumberStatus(validations: SectionValidation[], status: number) {
    if (!validations) {
      return 0;
    }
    const result = jQuery.grep(validations, function (e) {
      return e.status === status;
    });


    if (!result) {
      return 0;
    } else {
      return result.length;
    }
  }

  static initSelect(className: string, isSearch?: boolean) {
    const select = jQuery('.' + className);
    if (isSearch === false) {
      select.select2({
        minimumResultsForSearch: Infinity
      });
    } else {
      select.select2();
    }
  }

  static setValuesjQueryCmp(className: string, values: any, timout: number) {

    if (!values) {
      values = 0;
    }

    setTimeout(function () {
      const component = jQuery('.' + className);
      component.val(values).trigger('change');
    }, timout);


  }

  static getCurrentUniversityYear(cycle: number) {
    // cycle => 1 : PDCEM , 2 : IRESIDANT
    let fullYear = new Date().getFullYear();
    if (cycle === 1) {
      if (new Date().getMonth() >= 0 && new Date().getMonth() <= 5) {
        fullYear -= 1;
      }
    } else {
      if (new Date().getMonth() >= 0 && new Date().getMonth() <= 7) {
        fullYear -= 1;
      }
    }
    return (fullYear) + "-" + (fullYear + 1)
  }

  static getLabelNiveau(niveau_id: number) {
    switch (niveau_id) {
      case 1 :
        return "PCEM1";
      case 2 :
        return "PCEM2";
      case 3 :
        return "DCEM1";
      case 4 :
        return "DCEM2";
      case 5 :
        return "DCEM3";
      case 6 :
        return "DCEM4";
      case 7 :
        return "DCEM4 (AR)";
      case 8:
        return "TCEM1";
      case 9:
        return "TCEM2";
      case 10:
        return "TCEM3";
      case 11:
        return "TCEM4";
      case 12:
        return "TCEM5";

    }
  }


  static getLevelIdFromName(niveau_name: any) {
    switch (niveau_name) {
      case "PCEM1" :
        return 1;
      case "PCEM2" :
        return 2;
      case "DCEM1" :
        return 3;
      case "DCEM2" :
        return 4;
      case "DCEM3" :
        return 5;
      case "DCEM4" :
        return 6;
      case "DCEM4(AR)" :
        return 7;
      case "TCEM1" :
        return 8;
      case "TCEM2" :
        return 9;
      case "TCEM3" :
        return 10;
      case "TCEM4" :
        return 11;
      case "TCEM5" :
        return 12;
    }
  }

  static getResultIdFromName(result_name: any) {
    switch (result_name) {
      case "Redoublant" :
        return 1;
      case "Admis" :
        return 2;
      case "Admis avec crédit" :
        return 3;
      case "admis et orienté" :
        return 5;
    }
  }

  static getResultNameFromId(result_id: number) {
    switch (result_id) {
      case 1 :
        return "Redoublant";
      case  2:
        return "Admis";
      case 3:
        return "Admis avec crédit";
      case 5 :
        return "admis et orienté";
    }
  }


  static setValueToRadioBox(className: string, value: any, timout: number) {
    const radioBox = jQuery('.' + className).prop('checked', false);
    jQuery.uniform.update(radioBox);
    if (value !== undefined) {
      setTimeout(function () {
        const radioBox = jQuery('.' + className + '[value=' + value + ']').prop('checked', true);
        jQuery.uniform.update(radioBox);
      }, timout);
    }
  }

  static initDatePicker(className: string, timePicker: boolean) {
    jQuery('.' + className).daterangepicker({
      timePicker: timePicker,
      timePicker24Hour: true,
      singleDatePicker: true,
      showDropdowns: true,
      locale: {
        format: 'DD/MM/YYYY HH:mm'
      }
    });

  }

  static getRandomString(length: number = 10) {
    var text = "";
    var possible = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";

    for (var i = 0; i < length; i++)
      text += possible.charAt(Math.floor(Math.random() * possible.length));

    return text;
  }

  static getIdentifiantStudent(student: any) {
    if (!student.cin && !student.passport) {
      return "-";
    }
    return student.cin ? student.cin.code : student.passport.code;
  }

  static convertToFormation(itemsSession: ItemSessionFormation[]) {

    let formations: Formation[] = [];

    itemsSession.forEach(
      item => {
        let formation = item.formation;
        formation['capacite'] = item.nb_places;
        formations.push(formation);
      }
    );
    return formations;
  }

  static getIdByString(element: string, key: string, value: string, objects: any) {

    // console.log(objects);
    for (let i = 0; i < objects.length; i++) {
      if (objects[i][value].trim().toLowerCase() === element.trim().toLowerCase()) {
        return objects[i][key];
      }
    }
  }

  static getStatusDossier(dossier_status: number, administration_review: number) {
    /*
    Les Etats:
       * Pas envoyé
       * En Cours de validation
       * Validé
       * Refusé
       * A réviser
     */
    /*
     * dossier_status => {0=> not completed;1=> submited and waiting for review;2=> in review;3=> reviewed}
     * administration_review=>{0=>none; 1=> valid dossier;-1=> refused, 2=> needs to review}
     */
    if (dossier_status === 0) {
      return "Pas Envoyé"
    } else {
      if (administration_review === 1) {
        return "Validé"
      } else if (dossier_status === 1) {
        return "En Cours de validation"
      } else if (administration_review === 2) {
        return "A revisé"
      } else {
        return "-"
      }
    }

  }

  static getHours() {
    const res = [];
    const times = ['00', '15', '30', '45'];

    for (let i = 0; i < 24; i++) {
      let s = "";
      s = s + i;
      if (i < 10) {
        s = "0" + s;
      }
      s = s + ":";
      const fixS = s;
      for (let j = 0; j < times.length; j++) {
        res.push(fixS + times[j]);
      }
    }
    return res;
  }

  static convertDateToString(date: Date) {

    let day = date.getDate() + "";
    if (parseInt(day, 0) < 10) {
      day = "0" + day;
    }
    return date.getFullYear() + "-" + (date.getMonth() + 1) + "-" + day;
  }

  static getTime(date: string) {
    return date.substring(11, 16);
  }
}


export class InitialPreviewConfig {
  caption?: string;
  size?: number;
  width?: string;
  type?: string;
  filetype?: string;
  url: string;
  key: number;
  downloadUrl?: string;
}

